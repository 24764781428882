/* global Promise */

import * as regScroll from 'registrationScroll';
import * as nanoajax from 'nanoajax';
import * as token from 'utilities/token';

let form;
let submit;

let rulesCheckbox;
let personalInfoCheckbox;

let isRulesChecked;
let isPersonalInfoOk;

function who() {
  return document.querySelector('.registrationForm');
}

function isVip() {
  return form.classList.contains('js-vip');
}

function enableSubmit() {
  submit.disabled = false;
}

function disableSubmit() {
  submit.disabled = true;
}

function findElements() {
  form = document.querySelector('.registrationForm');
  submit = form.querySelector('.registrationSubmit');
  rulesCheckbox = form.querySelector('#rules_agree');
  personalInfoCheckbox = form.querySelector('#data_processing');
}

function isAllValid() {
  return isPersonalInfoOk && isRulesChecked;
}

function onInput(event) {
  const { target } = event;
  if (target === rulesCheckbox) isRulesChecked = target.checked;
  if (target === personalInfoCheckbox) isPersonalInfoOk = target.checked;
  if (rulesCheckbox.checked) isRulesChecked = true;
  if (isAllValid()) enableSubmit();
  else disableSubmit();
}

function collectData() {
  const data = new FormData(form);

  data.append('_token', token.getToken());

  return data;
}

function sendForm() {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({
      url: form.action,
      method: 'POST',
      body: collectData(),
    }, (code, response) => {
      const data = JSON.parse(response);
      if (code === 200 && data.success) resolve(data);
      else reject(data);
    });
  });
}

function redirect(data) {
  form.reset();
  if (data.redirect_to) location.href = data.redirect_to;
  else location.href = form.dataset.auth;
}

function handleError(data) {
  form.reset();
  console.error(data, 'error');
}

function sendVipForm() {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({
      url: form.action,
      method: 'POST',
      body: new FormData(form),
    }, (code, response) => {
      const data = JSON.parse(response);
      if (code === 200 && data.success) resolve(data);
      else reject(data);
    });
  });
}

function onSubmit(event) {
  event.preventDefault();
  disableSubmit();
  if (!isVip()) {
    sendForm()
      .then(redirect)
      .catch(handleError);
  } else {
    sendVipForm()
      .then(redirect)
      .catch(handleError);
  }
}

function subscribe() {
  form.addEventListener('input', onInput);
  form.addEventListener('change', onInput, false);
  form.addEventListener('submit', onSubmit);
}


export function init() {
  if (who()) {
    findElements();
    regScroll.init(form);
    subscribe();
  }
}

import FontFaceObserver from 'fontfaceobserver';

let text;

function who() {
  return document.querySelector('.indexHead');
}

function findElements() {
  text = document.querySelector('.indexHead');
}

const isOverflown = ({ scrollWidth }) => scrollWidth > document.body.clientWidth;

const resizeText = ({ element, elements, minSize = 10, maxSize = 512, step = 1, unit = 'px' }) => {
  (elements || [element]).forEach((el) => {
    let i = minSize;
    let overflow = false;


    while (!overflow && i < maxSize) {
      el.style.setProperty('--fontSize', `${i}${unit}`);
      overflow = isOverflown(el);

      if (!overflow) i += step;
    }

    // Revert to last state where no overflow happened
    el.style.setProperty('--fontSize', `${i - step}${unit}`);
    el.parentElement.style.setProperty('--margin-gap', `${el.clientHeight / 2}px`);
  });
};

function onResize() {
  resizeText({ element: text, step: 2 });
}

function subscribe() {
  const watcher = new ResizeObserver(onResize);
  watcher.observe(document.body);
}

export function init() {
  if (who()) {
    findElements();
    const font = new FontFaceObserver('PF DinDisplay Pro', {
      weight: 500,
    });
    font.load().then(() => {
      subscribe();
      requestAnimationFrame(() => {
        resizeText({ element: text, step: 2 });
      });
    });
  }
}


/* eslint-disable require-unicode-regexp */
/* global Promise */

import * as token from 'utilities/token';
import * as phoneField from 'form/phone';
import * as validate from 'form/validate';
import * as nanoajax from 'nanoajax';
import * as overlay from 'overlay';

let form;
let submit;
let phone;
let code;
let loginSteps;
let resendLink;
let email;

let phoneSent;

let isValid;

function who() {
  return document.querySelector('.loginForm');
}

function findElements() {
  form = document.querySelector('.loginForm');
  submit = form.querySelector('.loginSubmit');
  phone = form.querySelector('#phone');
  email = form.querySelector('#email');
  code = form.querySelector('#code');
  loginSteps = [].slice.call(form.querySelectorAll('.loginStep'));
  resendLink = form.querySelector('.resendCode');
}

function showResendLink() {
  resendLink.classList.add('resendCode-is-active');
}

function initPhoneField() {
  phoneField.init(phone, form);
}

function isPhone(target) {
  return target === phone;
}

function isEmail(target) {
  return target === email;
}

function isSMS(target) {
  return target === code;
}

function enableSubmit() {
  submit.removeAttribute('disabled');
}

function disableSubmit() {
  submit.setAttribute('disabled', true);
}

function checkSubmit() {
  if (isValid) enableSubmit();
  else disableSubmit();
}

function getPhoneUrl() {
  return form.dataset.sendUrl;
}

function getCodeUrl() {
  return form.dataset.confirmUrl;
}

function getUrl() {
  if (phoneSent) return getCodeUrl();
  return getPhoneUrl();
}

function collectPhoneData() {
  const data = new FormData();

  data.append('phone', phoneField.makeUpPhone());
  data.append('email', email.value);
  data.append('_token', token.getToken());

  return data;
}

function collectCodeData() {
  const data = new FormData();

  data.append('phone', phoneField.makeUpPhone());
  data.append('code', code.value);
  data.append('email', email.value);
  data.append('country_code', phone.dataset.code);
  data.append('country_iso2_code', phone.dataset.country);
  data.append('_token', token.getToken());

  return data;
}

function collectData() {
  if (phoneSent) return collectCodeData();
  return collectPhoneData();
}

function sendForm(ajaxUrl) {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({
      url: ajaxUrl || getUrl(),
      method: 'POST',
      body: collectData(),
    }, (responseCode, response) => {
      const data = JSON.parse(response);
      if (responseCode === 200 && data.success) resolve(data);
      else reject(data);
    });
  });
}

function onInput(event) {
  const { target } = event;
  if (isPhone(target) || isEmail(target)) isValid = validate.validatePhone(phone) || validate.validateEmail(email);
  else if (isSMS(target)) isValid = validate.validateCode(target);

  checkSubmit();
}

function onReset() {
  disableSubmit();
}

function swpapSteps() {
  form.previousElementSibling.remove();
  loginSteps[0].classList.remove('loginStep-is-active');
  loginSteps[1].classList.add('loginStep-is-active');
}

function showMessage(data) {
  overlay.show('errorPopup', data.message);
}

function handleError(data) {
  if (data.message) showMessage(data);
}

function redirectPage(href) {
  location.href = href;
}

function setCodeSubmit(data) {
  if (data.redirect_to) redirectPage(data.redirect_to);
  swpapSteps();
  code.value = '';
  phoneSent = true;
}

function setResendTimer() {
  setTimeout(showResendLink, 3000);
}

function onSubmit(event) {
  event.preventDefault();
  disableSubmit();
  sendForm()
    .then(setCodeSubmit)
    .then(setResendTimer)
    .catch(handleError);
}

function sendRequest(url) {
  sendForm(url);
}

function onResendClick(event) {
  event.preventDefault();
  const resendUrl = getPhoneUrl();
  sendRequest(resendUrl);
}

function onCountryChange() {
  form.reset();
}

function subscribe() {
  form.addEventListener('input', onInput);
  form.addEventListener('change', onInput, false);
  form.addEventListener('reset', onReset);
  form.addEventListener('submit', onSubmit);
  form.addEventListener('country:change', onCountryChange);
  resendLink.addEventListener('click', onResendClick);
}

export function init() {
  if (who()) {
    phoneSent = false;
    findElements();
    initPhoneField();
    subscribe();
  }
}

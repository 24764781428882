/* eslint-disable require-unicode-regexp */
export function validatePhone(field) {
  const placeholder = field.getAttribute('placeholder').replace(/\D/g, '');
  const { length } = placeholder;
  const valueLength = field.value.replace(/\D/g, '').length;

  return length === valueLength;
}

const emailRegexp = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g);

export function validateEmail(field) {
  return field.value.match(emailRegexp);
}

export function validateCode(field) {
  return field.value.length > 3;
}

export function validateFacebookUrl(field) {
  return field.value.length > 10;
}

export function validateIndex(field) {
  return field.value.length > 5;
}

export function validateCity(field) {
  return field.value.length > 0;
}

export function validateStreet(field) {
  return field.value.length > 0;
}

export function validateName(field) {
  return field.value.length > 0;
}

export function validateSimpleField(field) {
  return field.value.length > 0;
}

export function validatePassDate(field) {
  return field.value.length > 0;
}

export function validatePassword(field) {
  return field.value.length > 0;
}

/* global Promise */
/* global ym */
/* global gtag */

import * as nanoajax from 'nanoajax';
import * as validation from 'form/validate';
import * as overlay from 'overlay';
import * as token from 'utilities/token';

let form;
let name;
let email;
let submit;
let text;
let password;

let isNameOk;
let isEmailOk;
let isPasswordOk;

function who() {
  return document.querySelector('.feePayment');
}

function findElements() {
  form = document.querySelector('.feePayment');
  name = form.querySelector('#fio');
  email = form.querySelector('#email');
  password = form.querySelector('#password');
  submit = form.querySelector('.submitInitialFee');
  text = form.querySelector('.feeText');
}

function enableSubmit() {
  submit.removeAttribute('disabled');
}

function disableSubmit() {
  submit.setAttribute('disabled', true);
}

function allValid() {
  return isNameOk && isEmailOk && isPasswordOk;
}

function onInput(event) {
  const { target } = event;

  if (target === email) isEmailOk = validation.validateEmail(target);
  if (target === name) isNameOk = validation.validateName(target);
  if (target === password) isPasswordOk = validation.validatePassword(target);

  if (allValid()) enableSubmit();
  else disableSubmit();
}

function collectData() {
  const data = new FormData();
  data.append('_token', token.getToken());
  data.append('name', name.value);
  data.append('email', email.value);
  data.append('password', password.value);
  return data;
}

function sendMetrics() {
  if (ym) {
    ym(53147851, 'reachGoal', 'click_pay');
  }
  if (gtag) {
    gtag('event', 'send', {
      event_category: 'click_pay',
    });
  }
}

function sendInfo() {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({
      url: form.dataset.url,
      method: 'POST',
      body: collectData(),
    }, (code, response) => {
      const data = JSON.parse(response);
      if (code === 200 && data.success) resolve(data);
      else reject(data);
    });
  });
}

function showText() {
  text.style.display = 'block';
}

function payTinkoff() {
  window.pay(form);
}

function onError({ message }) {
  if (message) {
    overlay.show('errorPopup', message);
  }
}

function onSubmit(event) {
  event.preventDefault();
  sendMetrics();
  sendInfo()
    .then(payTinkoff)
    .then(showText)
    .catch(onError);
}

function subscribe() {
  form.addEventListener('input', onInput);
  form.addEventListener('change', onInput);
  form.addEventListener('submit', onSubmit);
}

export function init() {
  if (who()) {
    findElements();
    subscribe();
  }
}

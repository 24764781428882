import * as scroll from 'utilities/scroll';

let container;
let menuToggle;
let navigation;

let menuIsActive;

const ACTIVE_NAVIGATION_CLASS = 'headerNavigation-is-active';
const ACTIVE_MENU_BURGER_CLASS = 'menuToggle-is-active';

function findElements() {
  menuToggle = container.querySelector('.menuToggle');
  navigation = container.querySelector('.headerNavigation');
}

function toggleNavigation() {
  navigation.classList.toggle(ACTIVE_NAVIGATION_CLASS);
}

function toggleMenuBurger() {
  menuToggle.classList.toggle(ACTIVE_MENU_BURGER_CLASS);
}

function disableNavigation() {
  navigation.classList.remove(ACTIVE_NAVIGATION_CLASS);
}

function disableMenuBurger() {
  menuToggle.classList.remove(ACTIVE_MENU_BURGER_CLASS);
}

function setScroll() {
  if (menuIsActive) scroll.stop();
  else scroll.resume();
}

function onMenuToggleClick(event) {
  event.preventDefault();
  menuIsActive = !menuIsActive;
  toggleNavigation();
  toggleMenuBurger();
  setScroll();
}

export function isActive() {
  return menuIsActive;
}

export function close() {
  disableMenuBurger();
  disableNavigation();
  menuIsActive = false;
}

function subscribe() {
  menuToggle.addEventListener('click', onMenuToggleClick);
}

export function init(header) {
  container = header;
  menuIsActive = false;
  findElements();
  subscribe();
}

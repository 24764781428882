/* global intlTelInput */
/* global intlTelInputGlobals */
/* global Promise */
/* global intlTelInputUtils */

import IMask from 'imask';
import trigger from 'utilities/trigger';

const UTILS_SCRIPT_URL = 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/15.0.1/js/utils.js';

let phoneInput;
let selectInstance;
let maskInstance;
let phoneForm;

let countryData;
let countryCode;
let selectedCountries;

function setCountriesSelect() {
  return new Promise((resolve) => {
    intlTelInput(phoneInput, {
      separateDialCode: true,
      initialCountry: 'RU',
      autoHideDialCode: true,
      /*
       * OnlyCountries: [
       *   'ru', 'us', 'cz',
       * ],
       * PreferredCountries: [
       * ],
       */
      utilsScript: UTILS_SCRIPT_URL,
    });
    return resolve();
  });
}

function setInstance() {
  selectInstance = intlTelInputGlobals.getInstance(phoneInput);
}

function getMask() {
  // eslint-disable-next-line require-unicode-regexp
  return phoneInput.getAttribute('placeholder').replace(/\s/g, '-').replace(/\d/g, '0');
}

function setMask() {
  const mask = getMask();
  maskInstance = new IMask(phoneInput, {
    mask,
  });
}

function resetForm() {
  trigger(phoneForm, 'country:change', false, 'UIEvents');
}

function updateMask() {
  const mask = getMask();
  maskInstance.destroy();
  maskInstance = new IMask(phoneInput, {
    mask,
  });
}

function setDialCode() {
  const { dialCode } = selectInstance.getSelectedCountryData();
  phoneInput.setAttribute('data-code', dialCode);
}

function setCountryCode() {
  const { iso2 } = selectInstance.getSelectedCountryData();
  phoneInput.setAttribute('data-country', iso2);
}

function onCountryChange() {
  setDialCode();
  setCountryCode();
  resetForm();
  updateMask();
}

function subscribeEvents() {
  phoneInput.addEventListener('countrychange', onCountryChange);
}

function isNeededCountry(acc, item) {
  if (item.dialCode === countryCode) acc.push(item);
  return acc;
}

function findCountry(arr) {
  return arr.reduce(isNeededCountry, []);
}

function filterCountries(countryIso) {
  return selectedCountries.filter(country => country.iso2 === countryIso);
}

function setCountry() {
  if (phoneInput.dataset.initCode) {
    countryCode = phoneInput.dataset.initCode;
    countryData = intlTelInputGlobals.getCountryData();
    selectedCountries = findCountry(countryData);
    if (selectedCountries.length > 1) {
      const [currentCountry] = filterCountries(phoneInput.dataset.initCountry);
      selectInstance.setCountry(currentCountry.iso2);
    } else selectInstance.setCountry(selectedCountries[0].iso2);
  }
}

function replaceChars(string) {
  // eslint-disable-next-line require-unicode-regexp
  return string.replace(/\D/g, '');
}

export function makeUpPhone() {
  const phoneValue = replaceChars(phoneInput.value);
  const phoneCode = replaceChars(phoneInput.dataset.code);
  const currentPhone = `${phoneCode}${phoneValue}`;

  return currentPhone;
}

function triggerReady() {
  trigger(document, 'phone:ready', false, 'UIEvent');
}

function changePadding(padding) {
  selectInstance.telInput.style.paddingLeft = `${padding}px`;
}

function fixPadding() {
  const paddingLeft = parseInt(getComputedStyle(selectInstance.telInput).paddingLeft, 10);
  const realPadding = selectInstance.selectedFlag.offsetWidth + 6;
  if (paddingLeft > realPadding) changePadding(realPadding);
}

export function init(input, form) {
  phoneInput = input;
  phoneForm = form;
  setCountriesSelect()
    .then(setInstance)
    .then(setCountry)
    .then(setDialCode)
    .then(setCountryCode)
    .then(setMask)
    .then(subscribeEvents)
    .then(fixPadding)
    .then(triggerReady);
}

/* global Promise */

import * as resize from 'utilities/resize';

const ratio = {
  shiftX: 0,
  shiftY: 0,
};

const INCREASE_PERCENT = 1.25;

let video;
let src;

let videoRatio;
let videoWidth;
let videoHeight;

let pageRatio;
let pageWidth;
let pageHeight;

let canvas;
let context;

let canvasWidth;
let canvasHeight;
let canvasRatio;

let mobileCalculated;

function who() {
  return document.querySelector('#backgroundVideo');
}

function findElements() {
  video = document.querySelector('#backgroundVideo');
  ({ src } = video.dataset);
}

function updateVideoRatio() {
  ({ videoWidth, videoHeight } = video);
  videoRatio = videoWidth / videoHeight;
}

function updatePageRatio(measures) {
  ({ width: pageWidth, height: pageHeight } = measures);
  pageRatio = pageWidth / pageHeight;
}

function updateGlobalRatio() {
  if (videoRatio > pageRatio) {
    ratio.height = pageHeight;
    ratio.width = videoWidth * (ratio.height / videoHeight);
    ratio.shiftX = (pageWidth - ratio.width) / 2;
  } else {
    ratio.width = pageWidth;
    ratio.height = videoHeight * (ratio.width / videoWidth);
    ratio.shiftY = (pageHeight - ratio.height) / 2;
  }
}

function updateMobileRatio() {
  if (videoRatio > canvasRatio) {
    ratio.height = canvasHeight * INCREASE_PERCENT;
    ratio.width = videoWidth * (ratio.height / videoHeight);
    ratio.shiftX = (canvasWidth - ratio.width) / 2;
    ratio.shiftY = (canvasHeight - ratio.height) / 2;
  }
}

function updateCanvasSize() {
  canvas.width = pageWidth;
  canvas.height = pageHeight;
}

function updateMobileCanvasSize() {
  const vh = pageHeight * 0.01;
  canvasWidth = pageWidth;
  canvasHeight = vh * 45;
  canvasRatio = canvasWidth / canvasHeight;
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  canvas.style.setProperty('--vh', `${vh}px`);
}

function calculateNormalSize() {
  updateCanvasSize();
  updateGlobalRatio();
}

function calculateMobileSize() {
  if (!mobileCalculated) {
    updateMobileCanvasSize();
    updateMobileRatio();
    mobileCalculated = true;
  }
}

function onResize(measures) {
  const { isMobile } = measures;
  updatePageRatio(measures);
  if (isMobile) calculateMobileSize();
  else calculateNormalSize();
}

function createCanvas() {
  canvas = document.createElement('canvas');
  context = canvas.getContext('2d');
  canvas.className = 'videoCanvas';
  document.body.appendChild(canvas);
}

function onVideoLoad() {
  updateVideoRatio();
  createCanvas();
  video.play();
  resize.addTask(onResize);
  video.removeEventListener('loadeddata', onVideoLoad);
}

function renderImage() {
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.drawImage(video, ratio.shiftX, ratio.shiftY, ratio.width, ratio.height);
}

function playVideo() {
  renderImage();
  requestAnimationFrame(playVideo);
}

function onVideoPlay() {
  playVideo();
}

function loadVideo() {
  const html = `<source src="${src}" type="video/mp4">`;
  video.insertAdjacentHTML('beforeend', html);
}

function subscribe() {
  video.addEventListener('loadeddata', onVideoLoad);
  video.addEventListener('playing', onVideoPlay);
}

export function init() {
  if (who()) {
    findElements();
    subscribe();
    loadVideo();
  }
}

let form;
let holder;

function who() {
  return document.querySelector('.js-lotsFilter');
}

function findElements() {
  form = document.querySelector('.js-lotsFilter');
  holder = document.querySelector('.lots');
}

function updateHolder({ value }) {
  if (value === 'available') holder.classList.add('lots-is-filtered');
  else holder.classList.remove('lots-is-filtered');
}

function onChange({ target }) {
  updateHolder(target);
}

function subscribe() {
  form.addEventListener('change', onChange);
}

export function init() {
  if (who()) {
    findElements();
    subscribe();
  }
}

import { stop as stopScroll, resume as resumeScroll } from 'utilities/scroll';
import { obscurePage, revealPage, updateColor } from 'shade';
import { close as closeMenu } from 'hamburger';

const SHADE_COLOR = 'rgba(0, 0, 0, .4)';

let header;
let toggles;

function who() {
  return document.querySelector('.searchForm');
}

function findElements() {
  header = document.querySelector('.header');
  toggles = [].slice.call(header.querySelectorAll('.searchToggle'));
}

function hasSearch() {
  return header.classList.contains('header-has-search');
}

function toggleHeader() {
  header.classList.toggle('header-has-search');
}

function toggleScroll() {
  if (hasSearch()) {
    stopScroll();
    obscurePage();
    updateColor(SHADE_COLOR);
  } else {
    resumeScroll();
    revealPage();
  }
}

function onClick(event) {
  event.preventDefault();
  closeMenu();
  toggleHeader();
  toggleScroll();
}

function subscribeToggle(toggle) {
  toggle.addEventListener('click', onClick);
}

function subscribe() {
  toggles.forEach(subscribeToggle);
}

export function init() {
  if (who()) {
    findElements();
    subscribe();
  }
}

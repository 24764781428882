import * as resize from 'utilities/resize';
import * as scroll from 'utilities/scroll';
import trigger from 'utilities/trigger';

let form;
let floating;
let scrollLink;
let checkbox;

let edgePoint;

let isStuck;

const FLOATING_ACTIVE_CLASS = 'registrationFloat-is-stuck';


function findElements() {
  floating = form.querySelector('.registrationFloat');
  scrollLink = floating.querySelector('.floatScrollLink');
  checkbox = floating.querySelector('#rules_agree');
}

function showFloating() {
  floating.classList.add(FLOATING_ACTIVE_CLASS);
  isStuck = true;
}

function hideFloating() {
  floating.classList.remove(FLOATING_ACTIVE_CLASS);
  isStuck = false;
}

function setEdge() {
  edgePoint = form.offsetTop + floating.getBoundingClientRect().height;
}

function isChecked() {
  return checkbox.checked;
}

function shouldRun(measurements) {
  return measurements.position > 0 && measurements.position < edgePoint && !isChecked();
}

function shouldShow(measurements) {
  return measurements.height + measurements.position < edgePoint;
}

function changeFloating(measurements) {
  if (shouldRun(measurements)) {
    if (shouldShow(measurements)) showFloating();
    else hideFloating();
  }
}

function subscribe(measurements) {
  changeFloating(measurements);
}

function scrollToForm() {
  scroll.to(edgePoint);
}

function onLinkClick(event) {
  event.preventDefault();
  if (isStuck && !isChecked()) {
    hideFloating();
    checkbox.checked = true;
    trigger(form, 'input', false, 'Event');
  }
  scrollToForm();
}

function onCheckBoxInput() {
  if (isStuck && isChecked()) {
    scrollToForm();
    hideFloating();
  }
}

function subscribeLink() {
  scrollLink.addEventListener('click', onLinkClick);
}

function subscribeCheckbox() {
  checkbox.addEventListener('change', onCheckBoxInput);
}

export function init(node) {
  form = node;
  isStuck = false;
  findElements();
  subscribeLink();
  subscribeCheckbox();
  scroll.addTask(subscribe);
  resize.addTask(setEdge);
}

import slider from 'slider';

let elements;

let sliders;

function who() {
  return document.querySelector('.js-slider');
}

function findElements() {
  elements = [].slice.call(document.querySelectorAll('.js-slider'));
}

function initSliders() {
  sliders = elements.map(slider);
}

function destroyInstance(instance) {
  instance.destroy();
}

function destroyInstances() {
  sliders.forEach(destroyInstance);
}

function renderGalleryItem(item) {
  return `
    <div class="auctionBigSliderItem">
        <img src="${item.image}" width="455" height="305" alt="">
    </div>
    `;
}

function renderGallery(gallery) {
  return gallery.map(renderGalleryItem).join('');
}

function renderSliderItems(item, gallery) {
  item.insertAdjacentHTML('afterbegin', renderGallery(gallery));
}

function renderItems(gallery) {
  elements.forEach(element => renderSliderItems(element, gallery));
}

function onGalleryUpdate(event) {
  const { data } = event;
  destroyInstances();
  renderItems(data);
  initSliders();
}

function subscribeUpdate() {
  document.addEventListener('gallery:update', onGalleryUpdate);
}

export function init() {
  if (who()) {
    findElements();
    initSliders();
    subscribeUpdate();
  }
}

/* eslint-disable compat/compat */
// eslint-disable-next-line no-unused-vars
import * as observerPolyfill from 'intersection-observer';

let images;
let watcher;


function who() {
  return document.querySelector('.lots');
}

function canObserve() {
  return 'IntersectionObserver' in window;
}

function loadAllImages() {
  // eslint-disable-next-line no-param-reassign
  images.forEach((image) => { image.src = image.dataset.src; });
}

function findElements() {
  images = [].slice.call(document.querySelectorAll('.lazyload'));
}

function loadImage(target, observer) {
  const index = images.indexOf(target);
  // eslint-disable-next-line no-param-reassign
  target.src = target.dataset.src;
  images.splice(index, 1);
  observer.unobserve(target);
}

function setImage(entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) loadImage(entry.target, observer);
  });
}

function setObservers() {
  images.forEach(image => watcher.observe(image));
}

function subscribe() {
  if (canObserve()) {
    watcher = new IntersectionObserver(setImage, {
      rootMargin: '0px',
      threshold: 0.25,
    });
    setObservers();
  } else loadAllImages();
}

export function init() {
  if (who()) {
    findElements();
    subscribe();
  }
}

const size = {};
const cache = {};
const tasks = [];
let frame;

const MOBILE_THRESHOLD = 720;

function measurePage() {
  const { innerWidth, innerHeight } = window;
  size.height = innerHeight;
  size.width = innerWidth;
  size.isMobile = innerWidth < MOBILE_THRESHOLD;
}


function cacheSize() {
  cache.width = size.width;
  cache.isMobile = size.isMobile;
  cache.height = size.height;
}

function shouldRun() {
  return Object.keys(size).some(key => size[key] !== cache[key]);
}

function runTask(task) {
  if (size) task(size);
}

function runTasks() {
  tasks.forEach(runTask);
}

export function request() {
  return size;
}

function update() {
  measurePage();
  if (shouldRun()) {
    cacheSize();
    runTasks();
  }
}

export function addTask(task, runInitialy = true) {
  tasks.push(task);
  if (runInitialy) runTask(task);
}

export function removeTask(task) {
  const index = tasks.indexOf(task);
  if (index >= 0) tasks.splice(index, 1);
}

function onWindowResize() {
  if (tasks.length) {
    cancelAnimationFrame(frame);
    frame = requestAnimationFrame(update);
  }
}

function subscribe() {
  window.addEventListener('resize', onWindowResize);
}

export function init() {
  measurePage();
  subscribe();
}

/* global pay */

let form;
let button;

function who() {
  return document.querySelector('.paymentForm');
}

function findElements() {
  form = document.querySelector('.paymentForm');
  button = form.querySelector('.paymentFormSubmit');
}

function shouldDisableButton(target) {
  return target.value.length === 0;
}

function disableButton() {
  button.disabled = true;
}

function enableButton() {
  button.disabled = false;
}

function toggleButton({ target }) {
  if (shouldDisableButton(target)) disableButton();
  else enableButton();
}

function onInput(event) {
  toggleButton(event);
}

function onSubmit(event) {
  event.preventDefault();
  pay(event.currentTarget);
}

function subscribe() {
  form.addEventListener('input', onInput);
  form.addEventListener('submit', onSubmit);
}

export function init() {
  if (who()) {
    findElements();
    subscribe();
  }
}

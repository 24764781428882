import debounce from 'utilities/debounce';

let form;
let url;
let token;
let autocomplete;
let debouncedUpdate;
let debouncedClear;

function who() {
  return document.querySelector('.searchForm');
}

function findElements() {
  form = document.querySelector('.searchForm');
  ({ url, token } = form.dataset);
  autocomplete = document.querySelector('.searchAutocompleteHolder');
}

function shouldUpdate({ value }) {
  return value.length >= 3;
}

function collectData() {
  const data = new FormData(form);
  data.append('_token', token);
  return data;
}

function setOptions() {
  return {
    method: 'post',
    body: collectData(),
  };
}

function render({ result }) {
  autocomplete.innerHTML = `
    <div class="searchAutocomplete">
      ${result}
    </div>
  `;
}

function clear() {
  autocomplete.innerHTML = '';
}

function update() {
  // eslint-disable-next-line compat/compat
  fetch(url, setOptions())
    .then(response => response.json())
    .then(render);
}

function onInput({ target }) {
  if (shouldUpdate(target)) debouncedUpdate();
  else debouncedClear();
}

function subscribe() {
  form.addEventListener('input', onInput);
}

export function init() {
  if (who()) {
    debouncedUpdate = debounce(update, 500);
    debouncedClear = debounce(clear, 600);
    findElements();
    subscribe();
  }
}

import * as resize from 'utilities/resize';

const tasks = [];

let measurements = {};

let page;
let frame;

let stopFrame;

let height;
let prev;
let position;
let direction;

let currentScroll;

const FIXED_PAGE_CLASS = 'page-is-fixed';

function setPage() {
  page = document.body;
}

export function request() {
  return measurements;
}

function updateHeight(screen) {
  ({ height } = screen);
}

function measure() {
  prev = position;
  position = window.pageYOffset;
  direction = position - prev;
  measurements = { position, height, direction };
}

function runTask(task) {
  if (measurements) task(measurements);
}

function run() {
  tasks.forEach(runTask);
}

function update() {
  if (tasks.length) {
    measure();
    run();
  }
}

export function addTask(task, runInitialy = true) {
  tasks.push(task);
  if (runInitialy) runTask(task);
}

export function removeTask(task) {
  const index = tasks.indexOf(task);
  if (index >= 0) tasks.splice(index, 1);
}

function set(value) {
  window.scrollTo(0, value);
}

export function to(value, behavior = 'smooth') {
  window.scrollTo({
    top: value,
    behavior,
  });
}

function stopScroll() {
  set(currentScroll);
}

function cancellScroll() {
  cancelAnimationFrame(stopFrame);
  stopFrame = requestAnimationFrame(stopScroll);
}

export function stop() {
  page.classList.add(FIXED_PAGE_CLASS);
  currentScroll = window.pageYOffset;
  window.addEventListener('scroll', cancellScroll);
}

export function resume() {
  page.classList.remove(FIXED_PAGE_CLASS);
  window.removeEventListener('scroll', cancellScroll);
}

function onScroll() {
  cancelAnimationFrame(frame);
  frame = requestAnimationFrame(update);
}

function subscribe() {
  window.addEventListener('scroll', onScroll);
}

export function init() {
  setPage();
  resize.addTask(updateHeight);
  measure();
  subscribe();
}

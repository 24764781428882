import * as smallGallery from 'smallSlider';

let smallSlider;

function who() {
  return document.querySelector('.auction') || document.querySelector('.adminAuction');
}

function findElements() {
  smallSlider = document.querySelector('.auctionSmallSlider');
}

function initSLiders() {
  smallGallery.init(smallSlider);
}

export function init() {
  if (who()) {
    findElements();
    initSLiders();
  }
}

const DEFAULT_COLOR = 'rgba(255, 255, 255, .75)';

let shade;

let isActive;

function who() {
  return document.querySelector('.shade');
}

export function updateColor(color = DEFAULT_COLOR) {
  document.documentElement.style.setProperty('--shade-color', color);
}

export function resetColor() {
  updateColor();
}

export function obscurePage() {
  if (!isActive) {
    isActive = true;
    shade.classList.remove('shade-is-invisible');
    shade.classList.add('shade-is-visible');
  }
}

export function revealPage() {
  if (isActive) {
    isActive = false;
    shade.classList.remove('shade-is-visible');
    shade.classList.add('shade-is-invisible');
  }
}

function findElements() {
  shade = document.querySelector('.shade');
}

export function init() {
  if (who()) findElements();
}

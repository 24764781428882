import trigger from 'utilities/trigger';

let bigSlider;
let smallSlider;
let gallery;
let galleryNext;
let galleryPrev;

let smallSliderItems;

let activeImageIndex;

let controlsSubscribed;

const STEP_TRANSLATE = 98;

const CONTROLS_INACTIVE_CLASS = 'js-sliderNavigation-is-disabled';

function who() {
  return document.querySelector('.js-smallSlider');
}

function findElements() {
  bigSlider = document.querySelector('.auctionBigSlider');
  gallery = document.querySelector('.auctionSmallSliderHolder');
  smallSlider = gallery.querySelector('.auctionSmallSlider');
  galleryNext = gallery.querySelector('.js-smallSliderNext');
  galleryPrev = gallery.querySelector('.js-smallSliderPrev');
  smallSliderItems = [].slice.call(smallSlider.querySelectorAll('.auctionSmallSliderItem'));
}

function shouldUpdateSlider(target) {
  return target.classList.contains('auctionSmallSliderItem') && !target.classList.contains('auctionSmallSliderItem-is-active');
}

function updateActive(index = activeImageIndex) {
  if (smallSliderItems.length > 0) {
    if (activeImageIndex >= 0) {
      smallSliderItems[activeImageIndex].classList.remove('auctionSmallSliderItem-is-active');
    }

    activeImageIndex = index;
    smallSliderItems[activeImageIndex].classList.add('auctionSmallSliderItem-is-active');
  }
}

function activateNext() {
  galleryNext.classList.remove(CONTROLS_INACTIVE_CLASS);
}

function deactivateNext() {
  galleryNext.classList.add(CONTROLS_INACTIVE_CLASS);
}

function activatePrev() {
  galleryPrev.classList.remove(CONTROLS_INACTIVE_CLASS);
}

function deactivatePrev() {
  galleryPrev.classList.add(CONTROLS_INACTIVE_CLASS);
}

export function resetSlilder() {
  smallSlider.scrollLeft = 0;
}

function updateNavigation() {
  if (smallSlider.scrollLeft === 0) {
    deactivatePrev();
    activateNext();
  } else if (smallSlider.scrollWidth - smallSlider.scrollLeft === smallSlider.clientWidth) {
    activatePrev();
    deactivateNext();
  } else {
    activatePrev();
    activateNext();
  }
}

function showNextItem() {
  smallSlider.scrollLeft += STEP_TRANSLATE;
}

function showPrevItem() {
  smallSlider.scrollLeft -= STEP_TRANSLATE;
}

function onNextClick(event) {
  event.preventDefault();
  showNextItem();
}

function onPrevClick(event) {
  event.preventDefault();
  showPrevItem();
}

function subscribeSlider() {
  controlsSubscribed = true;
  galleryNext.addEventListener('click', onNextClick);
  galleryPrev.addEventListener('click', onPrevClick);
}

function startSlider() {
  activateNext();
  if (!controlsSubscribed) subscribeSlider();
}

function initSlider() {
  const lastSmallSliderItem = smallSliderItems[(smallSliderItems.length - 1)];
  if (lastSmallSliderItem) {
    const baseWidth = smallSlider.clientWidth;
    const contentWidth = lastSmallSliderItem.offsetLeft + lastSmallSliderItem.clientWidth;
    if (contentWidth > baseWidth) startSlider();
  }
}

function updateGallery(target) {
  const index = smallSliderItems.indexOf(target);
  updateActive(index);
  trigger(bigSlider, 'slider:set', false, 'UIEvent', { index: activeImageIndex + 1 });
}

function onSmallSliderClick(event) {
  event.preventDefault();
  const { target } = event;
  if (shouldUpdateSlider(target)) updateGallery(target);
}

function onSwipe(event) {
  updateActive(event.data.index - 1);
}

function eraseSliderItems() {
  while (smallSlider.firstChild) {
    smallSlider.removeChild(smallSlider.firstChild);
  }
}

function renderGalleryItem(galleryItem) {
  return `
        <a href="#" class="auctionSmallSliderItem">
          <img class="auctionSmallSliderImage" src="${galleryItem.image}" width="78" height="78" alt="">
        </a>
        `;
}

function renderGallery(lotGallery) {
  return lotGallery.map(renderGalleryItem).join('');
}

function renderNewSlider(lotGallery) {
  smallSlider.insertAdjacentHTML('afterbegin', renderGallery(lotGallery));
}

function updateItemsArray() {
  smallSliderItems = [].slice.call(smallSlider.querySelectorAll('.auctionSmallSliderItem'));
}

function onGalleryUpdate(event) {
  const { data } = event;
  eraseSliderItems();
  activeImageIndex = 0;
  renderNewSlider(data);
  resetSlilder();
  updateItemsArray();
  updateActive();
  trigger(bigSlider, 'slider:set', false, 'UIEvent', { index: activeImageIndex });
  initSlider();
}

function onScroll() {
  updateNavigation();
}

function subscribe() {
  bigSlider.addEventListener('slider:swap', onSwipe);
  smallSlider.addEventListener('click', onSmallSliderClick);
  smallSlider.addEventListener('scroll', onScroll);
  document.addEventListener('gallery:update', onGalleryUpdate);
}

export function init() {
  if (who()) {
    activeImageIndex = 0;
    findElements();
    updateActive();
    initSlider();
    subscribe();
  }
}

import { getLang } from 'utilities/lang';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

let currentLang;

let timeNode;
let time;
let globalDate;
let localDate;

function who() {
  return document.querySelector('.auctionNotStarted');
}

function findElements() {
  timeNode = document.querySelector('time');
  time = timeNode.dateTime;
}

function setLang() {
  currentLang = getLang();
}

function setAuctionTimeObj() {
  const global = new Date(time);
  globalDate = { global };
}

function getHours() {
  const hours = globalDate.global.getHours();
  if (hours < 10) return `0${hours}`;
  return hours;
}

/* eslint-disable */

function getLocalTimeZone() {
  const regexp = /\((.*?)\)/g;
  const [timezone] = regexp.exec(globalDate.global);
  return timezone;
}

/* eslint-enable */

function getMonth() {
  const month = globalDate.global.getMonth();
  return months[month];
}

function initLocalDate() {
  localDate = `${globalDate.global.getDate()}th of ${getMonth()} ${globalDate.global.getFullYear()} ${getHours()}:00 ${getLocalTimeZone()}`;
}

function changeLocalDate() {
  timeNode.innerHTML = localDate;
}

export function init() {
  if (who()) {
    setLang();
    if (currentLang === 'en') {
      findElements();
      setAuctionTimeObj();
      initLocalDate();
      changeLocalDate();
    }
  }
}

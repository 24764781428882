import * as txSlider from 'patterns/tx-slider';
import trigger from './utilities/trigger';

const AUTOPLAY_DURATION = 5000;

function isAutoplay(instance) {
  return instance.elements.node.classList.contains('js-sliderAutoplay');
}

function findElements(object) {
  const { elements } = object;
  const parent = elements.node.parentElement;
  elements.next = parent.querySelector('.js-slideNext');
  elements.prev = parent.querySelector('.js-slidePrev');
}

function calculateFullWidth(node) {
  const { children } = node;
  const { length } = children;
  const lastChild = children[length - 1];
  const { clientWidth, offsetLeft } = lastChild;
  return clientWidth + offsetLeft;
}

function calculateSize(elements) {
  const fullWidth = calculateFullWidth(elements.node);
  const width = Math.ceil(elements.node.getBoundingClientRect().width);
  return Math.ceil(fullWidth / width);
}

function disablePrev(prev, next) {
  if (prev) prev.classList.add('js-sliderNavigation-is-disabled');
  if (next) next.classList.remove('js-sliderNavigation-is-disabled');
}

function disableNext(prev, next) {
  if (prev) prev.classList.remove('js-sliderNavigation-is-disabled');
  if (next) next.classList.add('js-sliderNavigation-is-disabled');
}

function enableBoth(prev, next) {
  if (prev) prev.classList.remove('js-sliderNavigation-is-disabled');
  if (next) next.classList.remove('js-sliderNavigation-is-disabled');
}

function updateNavigation(instance) {
  const { gallery } = instance.slider;
  const { prev, next } = instance.elements;
  const current = gallery.current();
  const max = gallery.max();
  if (current === 0 && !instance.slider.autoplay) disablePrev(prev, next);
  else if (current === max) disableNext(prev, next);
  else enableBoth();
}

function hideNavigation(instance) {
  const { next, prev } = instance.elements;
  if (next) next.classList.add('js-sliderNavigation-is-disabled');
  if (prev) prev.classList.add('js-sliderNavigation-is-disabled');
}

function showNavigation(instance) {
  const { next, prev } = instance.elements;
  if (next) next.classList.remove('js-sliderNavigation-is-disabled');
  if (prev) prev.classList.remove('js-sliderNavigation-is-disabled');
}

function switchNavigation(instance) {
  const { slider } = instance;
  if (slider.size === 1) hideNavigation(instance);
  else showNavigation(instance);
}

function updateLoop(instance) {
  const max = instance.slider.gallery.max();
  const current = instance.slider.gallery.current();
  if (instance.slider.autoplay && current === max) instance.slider.gallery.set(1);
  if (instance.slider.autoplay && current === 0) instance.slider.gallery.set(max - 1);
}

function cloneSlide(element) {
  const firstSlide = element.children[0].cloneNode(true);
  const lastSlide = element.children[element.children.length - 1].cloneNode(true);
  element.appendChild(firstSlide);
  element.insertBefore(lastSlide, element.children[0]);
}

function initSliderAutoplay(instance, element) {
  const { slider } = instance;
  slider.autoplay = isAutoplay(instance);
  if (slider.autoplay) cloneSlide(element);
}

function startAutoplay(instance) {
  const { slider } = instance;
  clearTimeout(slider.timeout);
  slider.timeout = setTimeout(slider.gallery.next, AUTOPLAY_DURATION);
}

function updateAutoplay(instance) {
  if (instance.slider.autoplay) startAutoplay(instance);
}

function initSliderLogic(object) {
  const instance = object;
  const { elements, slider } = object;
  const size = calculateSize(elements);
  if (size > 1) {
    slider.autoplay = isAutoplay(instance);
    slider.size = size;
    slider.dots = txSlider.dots(slider.size, 'sliderDots', 'sliderDot', slider.autoplay);
    slider.gallery = txSlider.init(elements.node, slider.dots, 'sliderDot');
    if (elements.total) elements.total.textContent = slider.gallery.max() + 1;
    if (elements.total) elements.current.textContent = slider.gallery.current() + 1;
    updateNavigation(instance);
    switchNavigation(instance);
    if (slider.autoplay) {
      slider.gallery.set(1);
      startAutoplay(instance);
    } else slider.gallery.set(0);
  }
}

function reinitSlider(instance) {
  initSliderLogic(instance);
}

function subscribe(instance) {
  const object = instance;
  const { prev, next, node } = object.elements;
  object.slider.set = (event) => {
    const { index } = event.data;
    object.slider.gallery.slide(index);
  };
  object.slider.prev = (event) => { event.preventDefault(); object.slider.gallery.prev(); };
  object.slider.next = (event) => { event.preventDefault(); object.slider.gallery.next(); };
  // UpdateCurrent deleted
  object.slider.swipe = () => {
    updateAutoplay(object);
    updateLoop(object);
    updateNavigation(object);
    trigger(object.elements.node, 'slider:swap', false, 'UIEvent', { index: object.slider.gallery.current() });
  };
  if (prev) {
    prev.addEventListener('click', object.slider.prev);
    prev.addEventListener('touchstart', object.slider.prev);
  }
  if (next) {
    next.addEventListener('click', object.slider.next);
    next.addEventListener('touchstart', object.slider.next);
  }
  node.addEventListener('slider:set', object.slider.set);
  node.addEventListener('slider:swipe', object.slider.swipe);
}

function unsubscribe(instance) {
  const { prev, next, node } = instance.elements;
  if (prev) {
    prev.removeEventListener('click', instance.slider.prev);
    prev.removeEventListener('touchstart', instance.slider.prev);
  }
  if (next) {
    next.removeEventListener('click', instance.slider.next);
    next.removeEventListener('touchstart', instance.slider.next);
  }
  if (node) {
    node.removeEventListener('slider:set', instance.slider.set);
    node.removeEventListener('slider:swipe', instance.slider.swipe);
  }
}

function initSlider(instance, element) {
  const object = instance;
  const { elements, slider } = object;
  slider.size = calculateSize(elements);
  slider.dots = txSlider.dots(slider.size, 'js-sliderDots', 'js-sliderDot');
  slider.gallery = txSlider.init(elements.node, slider.dots, 'js-sliderDot');
  updateNavigation(instance);
  switchNavigation(instance);
  initSliderAutoplay(instance, element);
  initSliderLogic(instance);
}

function initInstance(instance, element) {
  const object = instance;
  const children = [].slice.call(element.children);
  const purchaseInfo = document.querySelector('.lotPurchaseInfo');
  const onlineAuction = document.querySelector('.onlineAuction');
  const engInfo = document.querySelector('.engInfo');
  const { height } = onlineAuction.getBoundingClientRect();
  const isAdmin = document.querySelector('.adminAuction');

  object.elements = {
    node: element,
    children,
    purchaseInfo,
    onlineAuction: {
      node: onlineAuction,
      height,
    },
    engInfo,
    isAdmin,
  };

  object.subscribed = false;
  object.slider = {};
  findElements(object);
  initSlider(object, element);
  subscribe(instance);
}

function reinitInstance(instance) {
  reinitSlider(instance);
  subscribe(instance);
}

function eraseItems(instance) {
  const { elements } = instance;
  const { node } = elements;
  while (node.firstChild) {
    node.removeChild(node.firstChild);
  }
}

function destroyInstance(instance) {
  unsubscribe(instance);
  if (instance.slider.autoplay) clearTimeout(instance.slider.timeout);
  eraseItems(instance);
  instance.slider.gallery.destroy();
}

function resetInstance(instance) {
  instance.slider.set({ event: { index: 0 } });
  if (instance.slider.autoplay) clearTimeout(instance.slider.timeout);
  reinitInstance(instance);
}

export default function sliderConstructor(element) {
  let instance = {};

  initInstance(instance, element);

  function destroy() {
    destroyInstance(instance);
    instance = null;
  }

  function reset() {
    resetInstance(instance);
  }

  return {
    instance,
    destroy,
    reset,
  };
}

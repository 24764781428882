let token;

function findToken() {
  token = document.querySelector('meta[name=csrf-token]');
}

export function getToken() {
  return token ? token.content : '';
}

export function init() {
  findToken();
}

/* eslint-disable require-unicode-regexp */
/* eslint-disable no-use-before-define */

/* global Promise */
import * as phoneField from 'form/phone';
import * as validation from 'form/validate';
import * as nanoajax from 'nanoajax';
import * as token from 'utilities/token';

let form;
let phone;
let methodInput;
let methodsList;
let methodsItems;
let submit;
let editLink;

let formObj;
let validationObj;

const LABEL_ACTIVE_CLASS = 'deliveryLabel-is-active';

const ACTIVE_METHODS_LIST_CLASS = 'deliveryMethodsList-is-active';

function who() {
  return document.querySelector('.deliveryForm');
}

function initFormObj() {
  formObj = {
    index: form.querySelector('#index'),
    city: form.querySelector('#city'),
    street: form.querySelector('#street'),
    phone,
    email: form.querySelector('#email'),
    method: methodInput,
  };
}

function initValidationObj() {
  validationObj = {
    index: false,
    city: false,
    street: false,
    phone: false,
    email: false,
    method: false,
  };
}

function findElements() {
  form = document.querySelector('.deliveryForm');
  phone = form.querySelector('#phone');
  submit = form.querySelector('.deliverySubmit');
  methodInput = form.querySelector('#method');
  methodsList = form.querySelector('.deliveryMethodsList');
  methodsItems = [].slice.call(methodsList.querySelectorAll('.methodItem'));
  editLink = form.querySelector('.deliveryChange');
  initFormObj();
}

function initPhoneField() {
  phoneField.init(phone, form);
}

function isAnotherDeliveryMethod(target) {
  return target.id === 'another';
}

function showMethodsList() {
  methodsList.classList.add(ACTIVE_METHODS_LIST_CLASS);
}

function hideMethodsList() {
  methodsList.classList.remove(ACTIVE_METHODS_LIST_CLASS);
}

function hideEditLink() {
  editLink.classList.remove('deliveryChange-is-active');
}

function showEditLink() {
  editLink.classList.add('deliveryChange-is-active');
}

function removeInitialClass() {
  form.classList.remove('js-editable-init');
}

function addFormEditableClass() {
  form.classList.add('deliveryEdit');
}

function removeFormEditableClass() {
  form.classList.remove('deliveryEdit');
}

function addDisabledClass() {
  form.classList.add('deliveryDisabled');
}

function removeDisabledClass() {
  form.classList.remove('deliveryDisabled');
}

function enableSubmit() {
  submit.removeAttribute('disabled');
}

function disableSubmit() {
  submit.setAttribute('disabled', true);
}

function disableDeliveryInput() {
  methodInput.setAttribute('disabled', true);
}

function enableDeliveryInput() {
  methodInput.removeAttribute('disabled', false);
}

function clearMethodInput() {
  methodInput.value = '';
}

function setMethodInputPlaceHolder() {
  methodInput.placeholder = methodInput.dataset.placeholder;
}

function removeMethodInputPlaceholder() {
  methodInput.removeAttribute('placeholder');
}

function addActiveLabelClass(label) {
  label.classList.add(LABEL_ACTIVE_CLASS);
}

function removeActiveLabelClass(label) {
  label.classList.remove(LABEL_ACTIVE_CLASS);
}

function isMethodOption(target) {
  return target.classList.contains('deliveryMethodLabel') || target.classList.contains('methodItem');
}

function isMethodInput(target) {
  return target === methodInput;
}

function isIndexInput(target) {
  return target.id === 'index';
}

function stuckLabel(label) {
  addActiveLabelClass(label);
}

function letGoLabel(label) {
  removeActiveLabelClass(label);
}

function setLabel(target) {
  const { value } = target;
  const label = target.nextElementSibling;
  if (label) {
    if (target.placeholder.length === 0) {
      if (value.length > 0) stuckLabel(label);
      else letGoLabel(label);
    }
  }
}

function fixInput(target) {
  // eslint-disable-next-line no-param-reassign
  target.value = target.value.replace(/\D/g, '');
}

function isValidObjValid() {
  return Object.keys(validationObj).every(key => validationObj[key]);
}

function isAllValid(target) {
  if (target.id === 'index') validationObj[target.id] = validation.validateIndex(target);
  if (target.id === 'city') validationObj[target.id] = validation.validateCity(target);
  if (target.id === 'street') validationObj[target.id] = validation.validateStreet(target);
  if (target.id === 'phone') validationObj[target.id] = validation.validatePhone(target);
  if (target.id === 'email') validationObj[target.id] = validation.validateEmail(target);
  return isValidObjValid();
}

function collectData() {
  const data = new FormData(form);
  data.append('_token', token.getToken());
  data.append('delivery_phone', phoneField.makeUpPhone());
  data.append('delivery_country_code', phone.dataset.code);
  data.append('delivery_country_iso2_code', phone.dataset.country);
  return data;
}

function sendForm() {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({
      url: form.action,
      method: 'POST',
      body: collectData(),
    }, (code, response) => {
      const data = JSON.parse(response);
      if (code === 200 && data.success) resolve(data);
      else reject(data);
    });
  });
}

function onInput(event) {
  const { target } = event;
  if (isMethodInput(target)) {
    if (target.value.length > 0) {
      setLabel(target);
    }
  } else {
    if (isIndexInput(target)) {
      fixInput(target);
    }
    setLabel(target);
  }
  if (isAllValid(target)) enableSubmit();
  else disableSubmit();
}

function usubscribeDocument() {
  document.removeEventListener('click', onDocumentClick);
  document.removeEventListener('touchend', onDocumentClick);
}

function onDocumentClick(event) {
  const { target } = event;
  if (!isMethodOption(target)) {
    hideMethodsList();
    usubscribeDocument();
  }
}

function subscribeDocument() {
  document.addEventListener('click', onDocumentClick);
  document.addEventListener('touchend', onDocumentClick);
}

function onFormClick(event) {
  const { target } = event;
  if (isMethodInput(target)) {
    event.stopPropagation();
    showMethodsList();
    subscribeDocument();
  }
}

function onMethodFocus(event) {
  event.stopPropagation();
  showMethodsList();
  subscribeDocument();
}

function setMethodInputValue(target) {
  const label = methodInput.nextElementSibling;
  const content = target.nextElementSibling.textContent;
  addActiveLabelClass(label);
  methodInput.value = content;
}

function onMethodItemChange(event) {
  const { target } = event;
  if (isAnotherDeliveryMethod(target)) {
    methodInput.focus();
    stuckLabel(methodInput.nextElementSibling);
    clearMethodInput();
    enableDeliveryInput();
    setMethodInputPlaceHolder();
  } else {
    removeMethodInputPlaceholder();
    setMethodInputValue(target);
    disableDeliveryInput();
  }
  validationObj.method = target.value;
  hideMethodsList();
}

function onMethodItemClick(event) {
  const { target } = event;
  if (target.checked) {
    hideMethodsList();
    if (isAnotherDeliveryMethod(target)) methodInput.focus();
  }
}

function subscribeMethodItem(item) {
  item.addEventListener('change', onMethodItemChange);
  item.addEventListener('click', onMethodItemClick);
}

function subscribeMethodItems() {
  methodsItems.forEach(subscribeMethodItem);
}

function onMethodInputBlur(event) {
  const { target } = event;
  if (target.value.length === 0) {
    if (target.placeholder.length === 0) {
      letGoLabel(target.nextElementSibling);
    }
  }
  hideMethodsList();
}

function onEditClick(event) {
  event.preventDefault();
  removeFormEditableClass();
  removeDisabledClass();
  enableSubmit();
  hideEditLink();
}

function handleSuccess() {
  addFormEditableClass();
  addDisabledClass();
  showEditLink();
}

function handleError() {
  // eslint-disable-next-line no-console
  console.error('Error!');
}

function onSubmit(event) {
  disableSubmit();
  event.preventDefault();
  sendForm()
    .then(handleSuccess)
    .catch(handleError);
}

function subscribe() {
  form.addEventListener('input', onInput);
  form.addEventListener('click', onFormClick);
  form.addEventListener('submit', onSubmit);
  methodInput.addEventListener('focus', onMethodFocus);
  methodInput.addEventListener('blur', onMethodInputBlur);
  subscribeMethodItems();
  editLink.addEventListener('click', onEditClick);
}

function isEditableInitially() {
  return form.classList.contains('js-editable-init');
}

function setInitialLabels() {
  Object.keys(formObj).forEach((key) => {
    if (formObj[key].value.length > 0) setLabel(formObj[key]);
  });
}

function updateValidObj() {
  Object.keys(formObj).forEach((key) => {
    if (formObj[key].value.length > 0) validationObj[key] = true;
  });
}

export function init() {
  if (who()) {
    findElements();
    initValidationObj();
    initPhoneField();
    subscribe();
    if (isEditableInitially()) {
      setInitialLabels();
      removeInitialClass();
      showEditLink();
      updateValidObj();
    }
  }
}

/* global Promise */

import * as nanoajax from 'nanoajax';
import * as token from 'utilities/token';

function setToken() {
  const data = new FormData();

  data.append('_token', token.getToken());
  return data;
}

function collectAdminForm(input) {
  const data = new FormData();

  data.append('_token', token.getToken());
  data.append('bid', parseInt(input.value, 10));

  return data;
}

export function sendForm(form, input) {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({
      url: form.action,
      method: 'POST',
      body: collectAdminForm(input),
    }, (code, response) => {
      const data = JSON.parse(response);
      if (code === 200 && data.success) resolve(data);
      else reject(data);
    });
  });
}

export default function request(url) {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({
      url,
      method: 'POST',
      body: setToken(),
    }, (code, response) => {
      const data = JSON.parse(response);
      if (code === 200 && data.success) resolve(data);
      else reject(data);
    });
  });
}

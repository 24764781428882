import * as socket from 'auction/socket';

function who() {
  return document.querySelector('.auctionNotStarted');
}

function onPreview(event) {
  location.href = event.data;
}

function subscribe() {
  document.addEventListener('auction:preview', onPreview);
}

export function init() {
  if (who()) {
    socket.init();
    subscribe();
  }
}

function setData(event, data) {
  const newEvent = event;
  newEvent.data = data;
  return newEvent;
}

function triggerCreateEvent(object, eventName, propagate, eventType, data) {
  const event = document.createEvent(eventType);
  if (data) setData(event, data);
  event.initEvent(eventName, propagate, false);
  object.dispatchEvent(event);
}

function triggerCreateEventObject(object, eventName, propagate, data) {
  const event = document.createEventObject();
  if (data) setData(event, data);
  object.fireEvent(`on${eventName}`, event);
}

export default function trigger(object, eventName, propagate = false, eventType = 'MouseEvents', data) {
  if (document.createEvent) {
    triggerCreateEvent(object, eventName, propagate, eventType, data);
  } else {
    triggerCreateEventObject(object, eventName, propagate, data);
  }
}

import * as resize from 'utilities/resize';
import * as scroll from 'utilities/scroll';
import * as token from 'utilities/token';
import * as header from 'header';
import * as loginPopup from 'loginPopup';
import * as overlay from 'overlay';
import * as vipLogin from 'vipLogin';
import * as backgroundVideo from 'backgroundVideo';
import * as delivery from 'delivery';
import * as addBid from 'addBid';
import * as registartion from 'registration';
import * as vipRegistration from 'vipRegistration';
import * as auction from 'auction';
import * as sliders from 'sliders';
import * as lazyload from 'lazyload';
import * as userAuction from 'auction/userAuction';
import * as author from 'author/author';
import * as authors from 'authors/authors';
import * as auctionNotStarted from 'auction/auctionNotStarted';
import * as feePayment from 'feePayment';
import * as popupLogin from 'popupLogin';
import * as recoveryRequest from 'recoveryRequest';
import * as passRecovery from 'passRecovery';
import * as search from 'search/search';
import * as shade from 'shade';
import * as startingDate from 'notStartedDate';
import * as paymentForm from 'payment/paymentForm';
import * as crawl from 'crawl';
import * as indexHead from 'indexHead';

resize.init();
scroll.init();
indexHead.init();
token.init();
header.init();
auction.init();
loginPopup.init();
overlay.init();
vipLogin.init();
backgroundVideo.init();
delivery.init();
addBid.init();
registartion.init();
vipRegistration.init();
sliders.init();
lazyload.init();
userAuction.init();
author.init();
authors.init();
auctionNotStarted.init();
feePayment.init();
popupLogin.init();
recoveryRequest.init();
passRecovery.init();
search.init();
shade.init();
startingDate.init();
paymentForm.init();
crawl.init();

/* eslint no-use-before-define: 'off' */

import * as scroll from 'utilities/scroll';
import * as menu from 'hamburger';

let overlays;
let activeOveray;

const ACTIVE_CLASS = 'overlay-is-active';
const ERROR_CLASS = 'error--state';

function who() {
  return document.querySelector('.overlay');
}

function isInitialyActive(obj) {
  return obj.node.classList.contains(ACTIVE_CLASS);
}

function isInputActive() {
  return document.activeElement.nodeName === 'INPUT';
}

function setObjects(overlaysArray) {
  return overlaysArray.map((overlay) => {
    const obj = {
      node: overlay,
      textNode: overlay.querySelector('.popupText'),
      childClass: overlay.firstElementChild.className,
    };
    if (isInitialyActive(obj)) activeOveray = obj;
    return obj;
  });
}

function findElements() {
  overlays = setObjects([].slice.call(document.querySelectorAll('.overlay')));
}

function showOverlay() {
  activeOveray.node.classList.add(ACTIVE_CLASS);
}

function hideOverlay() {
  activeOveray.node.classList.remove(ACTIVE_CLASS);
}

function findOverlay(className) {
  return overlays.reduce((acc, item) => {
    if (item.childClass.indexOf(className) !== -1) acc.push(item);
    return acc;
  }, []);
}

export function getOverlay(nodeClass) {
  return findOverlay(nodeClass)[0];
}

function unsubsribDocument() {
  document.removeEventListener('click', onDocumentClick);
  document.removeEventListener('touchstart', onDocumentClick);
}

function activeHasErrorState() {
  return activeOveray.node.classList.contains(ERROR_CLASS);
}

function removeErrorState() {
  activeOveray.node.classList.remove(ERROR_CLASS);
}

function closeOverlay() {
  if (activeHasErrorState()) {
    removeErrorState();
  }
  hideOverlay();
  unsubsribDocument();
  scroll.resume();
  activeOveray = null;
}

export function getCurrent() {
  return activeOveray;
}

export function close() {
  hideOverlay();
  unsubsribDocument();
  scroll.resume();
  activeOveray = null;
}

function matchOverlays(target) {
  return overlays.some(item => target === item.node);
}

function shouldClose(target) {
  return matchOverlays(target) && !isInputActive();
}

function onDocumentClick(event) {
  const { target } = event;

  if (shouldClose(target)) {
    closeOverlay();
  }
}

function subscribeDocument() {
  document.addEventListener('click', onDocumentClick);
  document.addEventListener('touchstart', onDocumentClick);
}

function changeContent(text) {
  activeOveray.textNode.textContent = text;
}

export function show(className, content) {
  ([activeOveray] = findOverlay(className));
  if (menu.isActive()) {
    menu.close();
  }
  if (content) {
    changeContent(content);
  }
  showOverlay();
  scroll.stop();
  subscribeDocument();
}

function onKeyDown(event) {
  const { keyCode, which } = event;

  if (keyCode === 27 || which === 27) {
    if (activeOveray) closeOverlay();
  }
}

function subscribeKeyBoard() {
  document.addEventListener('keydown', onKeyDown);
}

export function init() {
  if (who()) {
    findElements();
    subscribeKeyBoard();
    if (activeOveray) subscribeDocument();
  }
}

let form;
let holder;

function who() {
  return document.querySelector('.js-searchFilter');
}

function findElements() {
  form = document.querySelector('.js-searchFilter');
  holder = document.querySelector('.searchResult');
}

function updateHolder({ value }) {
  if (value === 'all') {
    holder.classList.remove('searchResult-is-authors');
    holder.classList.remove('searchResult-is-lots');
  } else if (value === 'authors') {
    holder.classList.add('searchResult-is-authors');
    holder.classList.remove('searchResult-is-lots');
  } else if (value === 'lots') {
    holder.classList.remove('searchResult-is-authors');
    holder.classList.add('searchResult-is-lots');
  }
}

function onChange({ target }) {
  updateHolder(target);
}

function subscribe() {
  form.addEventListener('change', onChange);
}

export function init() {
  if (who()) {
    findElements();
    subscribe();
  }
}

/* global Promise */

import * as validate from 'form/validate';
import * as nanoajax from 'nanoajax';

let form;
let submit;
let login;
let info;

let isLoginOk;

function who() {
  return document.querySelector('.recoveryRequest');
}

function findElements() {
  form = document.querySelector('.recoveryRequest');
  login = form.querySelector('#login');
  submit = form.querySelector('.submitButton');
  info = form.querySelector('.formInfo');
}

function showInfo(data) {
  if (data.results) info.textContent = data.results;
  info.classList.add('formInfo-is-visible');
}

function enableSubmit() {
  submit.removeAttribute('disabled');
}

function disableSubmit() {
  submit.setAttribute('disabled', true);
}

function setSubmitState() {
  if (isLoginOk) enableSubmit();
  else disableSubmit();
}

function onInput(event) {
  const { target } = event;

  if (target === login) isLoginOk = validate.validateEmail(target);

  setSubmitState();
}

function collectData() {
  const data = new FormData(form);
  return data;
}

function sendRequest() {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({
      url: form.action,
      method: 'POST',
      body: collectData(),
    }, (code, response) => {
      if (code === 200) {
        const data = JSON.parse(response);
        if (data.success) resolve(data);
        else reject(data);
      } else {
        reject(response);
      }
    });
  });
}

function submitForm() {
  sendRequest()
    .then(showInfo);
}

function onSubmit(event) {
  event.preventDefault();
  submitForm();
}

function subscribe() {
  form.addEventListener('input', onInput);
  form.addEventListener('change', onInput);
  form.addEventListener('submit', onSubmit);
}

export function init() {
  if (who()) {
    findElements();
    subscribe();
  }
}

let form;
let action;
let holder;

function who() {
  return document.querySelector('.js-authorsFilter');
}

function findElements() {
  form = document.querySelector('.js-authorsFilter');
  ({ action } = form);
  holder = document.querySelector('.authors');
}

function updateHolder({ result }) {
  holder.innerHTML = result;
}

function collectData(letter) {
  const data = new FormData(form);
  data.delete('letter');
  data.append('letter', letter);
  return data;
}

function setOptions({ value }) {
  return {
    method: 'post',
    body: collectData(value),
  };
}

function onChange({ target }) {
  // eslint-disable-next-line compat/compat
  fetch(action, setOptions(target))
    .then(response => response.json())
    .then(updateHolder);
}

function subscribe() {
  form.addEventListener('change', onChange);
}

export function init() {
  if (who()) {
    findElements();
    subscribe();
  }
}

import IMask from 'imask';

let dateField;

let dateMask;

function formatDate(date) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  return [day, month, year].join('.');
}

function parseDate(dateString) {
  const yearMonthDay = dateString.split('.');

  return new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0]);
}

function initDateField() {
  dateMask = new IMask(dateField, {
    mask: Date,
    pattern: 'd.`m.`Y',
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
      },
      m: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
      },
      Y: {
        mask: IMask.MaskedRange,
        from: 1950,
        to: 2022,
        maxLength: 4,
      },
    },
    format: formatDate,
    parse: parseDate,
    min: new Date(1950, 0, 1),
    max: new Date(2021, 12, 18),
  });
}

export function init(dateNode) {
  dateField = dateNode;
  initDateField();

  return dateMask;
}

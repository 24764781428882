import * as authToggle from 'authToggle';
import * as hamburger from 'hamburger';
import * as headerScroll from 'headerScroll';

let header;

function findElements() {
  header = document.querySelector('.header');
}

function isAdmin() {
  return header.querySelector('.adminNavigation');
}

export function init() {
  findElements();
  if (!header.classList.contains('auctionHeader')) {
    headerScroll.init(header);
  }
  hamburger.init(header);
  authToggle.init(header, isAdmin());
}

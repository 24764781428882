import * as scroll from 'utilities/scroll';
import * as resize from 'utilities/resize';

let header;

let isActive;
let subscribed;

const ACTIVE_CLASS = 'header-is-active';
const HIDDENT_CLASS = 'header-is-hidden';

function addActiveClass() {
  header.classList.add(ACTIVE_CLASS);
}

function removeActiveClass() {
  header.classList.remove(ACTIVE_CLASS);
}

function addHiddenClass() {
  header.classList.add(HIDDENT_CLASS);
}

function removeHiddenClass() {
  header.classList.remove(HIDDENT_CLASS);
}

function setHeaderActive() {
  removeHiddenClass();
  addActiveClass();
}

function setHederHidden() {
  removeActiveClass();
  addHiddenClass();
}

function setHeaderNormal() {
  removeActiveClass();
  removeHiddenClass();
  isActive = true;
}

function showHeader() {
  if (!isActive) {
    setHeaderActive();
    isActive = true;
  }
}

function hideHeader() {
  if (isActive) {
    setHederHidden();
    isActive = false;
  }
}

function setNormal() {
  setHeaderNormal();
}

function setHeaderInitially() {
  const { position } = scroll.request();
  if (position > 0) setHeaderActive();
}

function setHeader(measurements) {
  if (measurements.direction < 0) showHeader();
  else if (measurements.direction > 0) hideHeader();

  if (measurements.position === 0) setNormal();
}

function subscribe(measurements) {
  if (measurements.isMobile) {
    if (subscribed) {
      scroll.removeTask(setHeader);
      setNormal();
      subscribed = false;
    }
  } else if (!subscribed) {
    setHeaderInitially();
    scroll.addTask(setHeader, false);
    subscribed = true;
  }
}

export function init(node) {
  header = node;
  isActive = true;
  resize.addTask(subscribe);
}

/* eslint no-use-before-define: 'off' */

import * as resize from 'utilities/resize';

let container;
let authToggle;
let authNavigation;
let height;

let subscribed;

let isAuthActive;

const AUTH_TOGGLE_CLASS = 'profileToggle-is-active';
const AUTH_NAVIGATION_CLASS = 'authNavigation-is-active';

function findElements() {
  authToggle = container.querySelector('.profileToggle');
  authNavigation = container.querySelector('.authNavigation');
}

function authHeight() {
  const { children } = authNavigation;
  const { length } = children;
  const lastChild = children[length - 1];
  const { offsetTop, clientHeight } = lastChild;

  return offsetTop + clientHeight;
}

function paddingSize() {
  const styles = getComputedStyle(authNavigation);
  const { paddingBottom } = styles;

  return parseInt(paddingBottom, 10);
}

function calculateHeight() {
  setTimeout(() => {
    height = authHeight() + paddingSize();
  }, 100);
}

function setHeight() {
  authNavigation.style.height = `${height}px`;
}

function removeHeight() {
  authNavigation.removeAttribute('style');
}

function shouldHide(target) {
  return target !== authToggle && target !== authNavigation;
}

function onDocClick(event) {
  const { target } = event;
  if (shouldHide(target)) hideAuthMenu();
}

function subscribeClose() {
  document.addEventListener('click', onDocClick);
}

function unsubscribeClose() {
  document.removeEventListener('click', onDocClick);
}

function addActiveClasses() {
  authToggle.classList.add(AUTH_TOGGLE_CLASS);
  authNavigation.classList.add(AUTH_NAVIGATION_CLASS);
}

function removeActiveClasses() {
  authToggle.classList.remove(AUTH_TOGGLE_CLASS);
  authNavigation.classList.remove(AUTH_NAVIGATION_CLASS);
}

function hideAuthMenu() {
  removeActiveClasses();
  removeHeight();
  unsubscribeClose();
  isAuthActive = false;
}

function showAuthMenu() {
  addActiveClasses();
  setHeight();
  subscribeClose();
  isAuthActive = true;
}

function toggleAuthMenu() {
  if (isAuthActive) hideAuthMenu();
  else showAuthMenu();
}


function onAuthClick(event) {
  event.preventDefault();
  event.stopPropagation();
  toggleAuthMenu();
}

function onNavigationClick(event) {
  event.stopPropagation();
}

function subscribeEvents() {
  calculateHeight();
  authToggle.addEventListener('click', onAuthClick);
  authNavigation.addEventListener('click', onNavigationClick);
  subscribed = true;
}

function unsubscribeEvents() {
  authToggle.removeEventListener('click', onAuthClick);
  authNavigation.removeEventListener('click', onNavigationClick);
  subscribed = false;
}

function shouldSubscribe(measurements) {
  return !subscribed && !measurements.isMobile;
}

function shouldUnsubscribe(measurements) {
  return subscribed && measurements.isMobile;
}

function subscribe(measurements) {
  if (authToggle) {
    if (shouldSubscribe(measurements)) {
      subscribeEvents();
    } else if (shouldUnsubscribe(measurements)) {
      unsubscribeEvents();
    }
  }
}

export function init(header, isAdmin) {
  isAuthActive = false;
  subscribed = false;
  container = header;
  if (!isAdmin) {
    findElements();
    resize.addTask(subscribe);
  }
}

/* global Promise */

import * as validate from 'form/validate';
import * as nanoajax from 'nanoajax';

let form;
let password;
let passRepeat;
let submit;

let isPasswordOk;
let isPassRepeateOk;

function who() {
  return document.querySelector('.passRecovery');
}

function findElements() {
  form = document.querySelector('.passRecovery');
  password = form.querySelector('#newPassword');
  passRepeat = form.querySelector('#passRepeat');
  submit = form.querySelector('.submitButton');
}

function isSameValue(target) {
  return target.value === password.value;
}

function enableSubmit() {
  submit.removeAttribute('disabled');
}

function disableSubmit() {
  submit.setAttribute('disabled', true);
}

function setSubmitState() {
  if (isPassRepeateOk && isPasswordOk) enableSubmit();
  else disableSubmit();
}

function onInput() {
  const { target } = event;


  if (target === password) isPasswordOk = validate.validatePassword(target);
  if (target === passRepeat) isPassRepeateOk = validate.validatePassword(target) && isSameValue(target);

  setSubmitState();
}

function collectData() {
  const data = new FormData(form);

  return data;
}

function sendForm() {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({
      url: form.action,
      method: 'POST',
      body: collectData(),
    }, (code, response) => {
      if (code === 200) {
        const data = JSON.parse(response);
        if (data.success) resolve(data);
        else reject(data);
      } else {
        reject(response);
      }
    });
  });
}

function handleResponse(data) {
  if ((data.results && data.results.redirect_to) || data.redirect_to) {
    location.href = data.redirect_to || data.results.redirect_to;
  }
}

function submitForm() {
  sendForm()
    .then(handleResponse);
}

function onSubmit(event) {
  event.preventDefault();
  submitForm();
}

function subscribe() {
  form.addEventListener('input', onInput);
  form.addEventListener('change', onInput);
  form.addEventListener('submit', onSubmit);
}

export function init() {
  if (who()) {
    findElements();
    subscribe();
  }
}

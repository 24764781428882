import * as overlay from 'overlay';

let link;
let stubLink;

function who() {
  return document.querySelector('.loginLink') || document.querySelector('.stubLogin');
}

function findElements() {
  link = document.querySelector('.loginLink');
  stubLink = document.querySelector('.stubLogin');
}

function onLinkClick(event) {
  event.preventDefault();
  overlay.show('loginPopup');
}

function subscribe() {
  link.addEventListener('click', onLinkClick);
  if (stubLink) stubLink.addEventListener('click', onLinkClick);
}

export function init() {
  if (who()) {
    findElements();
    subscribe();
  }
}

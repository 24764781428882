import Echo from 'laravel-echo';
import io from 'socket.io-client/dist/socket.io.js';
import trigger from 'utilities/trigger';

function getStatus(event) {
  return event.status.toLowerCase();
}

function findPort() {
  return document.querySelector('meta[name=pusher-port]').content;
}

function initSocket(port) {
  window.io = io;
  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: `${location.hostname}:${port}`,
  });
}

function onAuctionStatusUpdate(event) {
  const auctionStatus = getStatus(event);
  trigger(document, `auction:${auctionStatus}`, false, 'UIEvent', event.redirect_to);
}

function onBidUpdate(event) {
  trigger(document, 'currentSum:update', false, 'UIEvent', event);
}

function onStreamUpdate(event) {
  trigger(document, 'stream:update', false, 'UIEvent', event);
}

function onLotUpdate(event) {
  trigger(document, 'lots:update', false, 'UIEvent', event);
}

function subscribeEvents() {
  window.Echo.private('Auction').listen('.auctionStatusUpdate', onAuctionStatusUpdate);
  window.Echo.private('Auction').listen('.currentLotBidUpdate', onBidUpdate);
  window.Echo.private('Auction').listen('.streamUpdate', onStreamUpdate);
  window.Echo.private('Auction').listen('.updateLot', onLotUpdate);
}

export function init() {
  const port = findPort();
  initSocket(port);
  subscribeEvents();
}

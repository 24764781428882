/* global Promise */

import * as validate from 'form/validate';
import * as nanoajax from 'nanoajax';

let form;

let email;
let password;
let submit;

let isEmailOk;
let isPassOk;

function who() {
  return document.querySelector('.popupLoginForm');
}

function findElements() {
  form = document.querySelector('.popupLoginForm');
  email = form.querySelector('#email');
  password = form.querySelector('#password');
  submit = form.querySelector('.submitButton');
}

function isAllValid() {
  return isEmailOk && isPassOk;
}

function enableSubmit() {
  submit.removeAttribute('disabled');
}

function disableSubmit() {
  submit.setAttribute('disabled', true);
}

function setSubmitState() {
  if (isAllValid()) enableSubmit();
  else disableSubmit();
}

function onInput(event) {
  const { target } = event;
  if (target === email) isEmailOk = validate.validateEmail(target);
  if (target === password) isPassOk = validate.validatePassword(target);
  setSubmitState();
}

function collectData() {
  const data = new FormData(form);
  return data;
}

function renderError({ message }) {
  submit.insertAdjacentHTML('beforebegin', `<span>${message}</span>`);
}

function sendForm() {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({
      url: form.action,
      method: 'POST',
      body: collectData(),
    }, (code, response) => {
      const data = JSON.parse(response);
      if (code === 200 && data.success) resolve(data);
      else {
        renderError(data);
        reject(data);
      }
    });
  });
}

function handleRedirect(data) {
  if (data.redirect_to) location.href = data.redirect_to;
}

function submitForm() {
  sendForm()
    .then(handleRedirect);
}

function onSubmit(event) {
  event.preventDefault();
  submitForm();
}

function subscribe() {
  form.addEventListener('input', onInput);
  form.addEventListener('change', onInput);
  form.addEventListener('submit', onSubmit);
}

export function init() {
  if (who()) {
    findElements();
    subscribe();
  }
}

/* global Promise */

import * as nanoajax from 'nanoajax';
import * as token from 'utilities/token';

const padding = {};

let bids;
let activeBid;
let initializedBids;

function who() {
  return document.querySelector('.bidAdd') && !document.querySelector('.bid-forbidden');
}

function initInstances(bidsArray) {
  return bidsArray.map(bid => ({
    form: {
      node: bid,
      addUrl: bid.action,
      id: bid.dataset.lotId,
      memberId: bid.querySelector('input[name=auction_member_id]').value,
    },
    input: {
      node: bid.querySelector('.overrideBidInput'),
      isPadding: false,
      isFocused() {
        return this.node === document.activeElement;
      },
    },
    submit: {
      node: bid.querySelector('.overrideBidSubmit'),
      text: bid.querySelector('.overrideBidSubmit').value,
      addText: bid.dataset.addText,
      editText: bid.dataset.editText,
      isActive: false,
    },
    text: {
      node: bid.querySelector('.bidText'),
      activeClass: 'bidText-is-active',
    },
    char: {
      node: bid.querySelector('.bidChar'),
      activeClass: 'bidChar-is-active',
      char: bid.dataset.char,
      isActive: false,
    },
    startPrice: parseInt(bid.dataset.start.replace(/\D/gu, ''), 10),
    placeholder: bid.querySelector('.bidPlaceHolder'),
    manipulation: {
      node: bid.querySelector('.bidManupulation'),
      activeClass: 'bidManupulation-is-active',
      isActive: false,
      isEdit: false,
      isCancel: false,
      editClass: 'bidManupulation-edit',
      cancelClass: 'bidManupulation-cancel',
    },
    measurer: bid.querySelector('.textMeasurer'),
    changed: false,
    state: 0,
    initialized: bid.dataset.initialized,
  }));
}

function findElements() {
  bids = initInstances([].slice.call(document.querySelectorAll('.bidAdd')));
}

function findBidByField(node, field) {
  return bids.reduce((acc, bid) => {
    if (bid[field].node === node) acc.push(bid);
    return acc;
  }, []);
}

function setPaddingInfo() {
  const styles = getComputedStyle(bids[0].input.node);
  padding.left = parseInt(styles.paddingLeft, 10);
  padding.top = parseInt(styles.paddingTop, 10);
  padding.right = parseInt(styles.paddingRight, 10);
  padding.bottom = parseInt(styles.paddingBottom, 10);
}

function resetActiveForm() {
  activeBid.form.node.reset();
}

function isEditManipulation(target) {
  return target.classList.contains(activeBid.manipulation.editClass);
}

function isCancelManipulation(target) {
  return target.classList.contains(activeBid.manipulation.cancelClass);
}

function shouldDiscardChanged(target) {
  return target !== activeBid.submit.node && target !== activeBid.manipulation.node;
}

function setActiveBidValue(value) {
  activeBid.input.node.value = value;
}

function getActiveBidValue() {
  return activeBid.input.node.value;
}

function setState() {
  if (getActiveBidValue().length > 0) activeBid.state = 1;
  else activeBid.state = 0;
}

function showManipulation() {
  activeBid.manipulation.node.classList.add(activeBid.manipulation.activeClass);
  activeBid.manipulation.isActive = true;
}

function hideManipulation() {
  activeBid.manipulation.node.classList.remove(activeBid.manipulation.activeClass);
  activeBid.manipulation.node.classList.remove(activeBid.manipulation.editClass);
  activeBid.manipulation.node.classList.remove(activeBid.manipulation.cancelClass);
  activeBid.manipulation.isActive = false;
  activeBid.manipulation.isEdit = false;
  activeBid.manipulation.isCancel = false;
}

function addInputPadding() {
  activeBid.input.node.style.paddingLeft = `${padding.left + activeBid.char.node.clientWidth}px`;
  activeBid.input.isPadding = true;
}

function removeInputPadding() {
  activeBid.input.node.style.paddingLeft = '';
  activeBid.input.isPadding = false;
}

function showChar(value) {
  activeBid.char.node.textContent = value;
  activeBid.char.node.classList.add(activeBid.char.activeClass);
  activeBid.char.isActive = true;
}

function hideChar() {
  if (activeBid.char.isActive) {
    activeBid.char.node.classList.remove(activeBid.char.activeClass);
    activeBid.char.node.removeAttribute('style');
    activeBid.char.isActive = false;
    if (activeBid.input.isPadding) removeInputPadding();
  }
}

function removeManipulationEditClass() {
  activeBid.manipulation.node.classList.remove(activeBid.manipulation.editClass);
  activeBid.manipulation.isEdit = false;
}

function addCancelManipulationClass() {
  activeBid.manipulation.node.classList.add(activeBid.manipulation.cancelClass);
  activeBid.manipulation.isCancel = true;
}

function removeCancelManipulationClass() {
  activeBid.manipulation.node.classList.remove(activeBid.manipulation.cancelClass);
  activeBid.manipulation.isCancel = false;
}

function addEditManipulationClass() {
  activeBid.manipulation.node.classList.add(activeBid.manipulation.editClass);
  activeBid.manipulation.isEdit = true;
}

function changeToEditManipulation() {
  removeCancelManipulationClass();
  addEditManipulationClass();
}

function changeToCancelManipulation() {
  removeManipulationEditClass();
  addCancelManipulationClass();
}

function setManipulation() {
  if (activeBid.state === 0) hideManipulation();
  else changeToEditManipulation();
}

function setActiveBidFocus() {
  activeBid.input.node.focus();
}

function setAddBidSubmitText() {
  activeBid.submit.node.value = activeBid.submit.addText;
}

function setEditBidSubmitText() {
  activeBid.submit.node.value = activeBid.submit.editText;
}

function changeSubmitText() {
  if (activeBid.state === 0) setAddBidSubmitText();
  else setEditBidSubmitText();
}

function showDiscardManipulation() {
  if (!activeBid.manipulation.isActive) showManipulation();
  if (activeBid.manipulation.isEdit) {
    removeManipulationEditClass();
    addCancelManipulationClass();
  } else addCancelManipulationClass();
}

function hideDiscardManupulation() {
  if (activeBid.state === 0) {
    if (activeBid.manipulation.isActive) {
      removeCancelManipulationClass();
      hideManipulation();
    }
  } else changeToEditManipulation();
}

function enableSubmit() {
  if (!activeBid.submit.isActive) {
    activeBid.submit.node.removeAttribute('disabled');
    activeBid.submit.isActive = true;
  }
}

function disableSubmit() {
  if (activeBid.submit.isActive) {
    activeBid.submit.node.setAttribute('disabled', true);
    activeBid.submit.isActive = false;
  }
}

function showSubmit() {
  activeBid.submit.node.classList.add('overrideBidSubmit-is-active');
}

function hideSubmit() {
  activeBid.submit.node.classList.remove('overrideBidSubmit-is-active');
}

function hidePlaceHolder() {
  activeBid.placeholder.classList.add('bidPlaceHolder-is-hidden');
}

function showPlaceHolder() {
  activeBid.placeholder.classList.remove('bidPlaceHolder-is-hidden');
}

function showText() {
  activeBid.text.node.classList.add(activeBid.text.activeClass);
}

function hideText() {
  activeBid.text.node.classList.remove(activeBid.text.activeClass);
}

function addChangedInputClass() {
  activeBid.input.node.classList.add('js-changed');
}

function removeChangedInputClass() {
  activeBid.input.node.classList.remove('js-changed');
}

function shouldEnableSumbitInput(value) {
  if (activeBid.state === 1) {
    return ((parseInt(value, 10) >= activeBid.startPrice) || value.length === 0) && activeBid.lastSubmitted !== value;
  }
  return parseInt(value, 10) >= activeBid.startPrice;
}

function setPlaceHolder(bid) {
  if (bid.char.char === '₽') bid.input.node.setAttribute('placeholder', `${bid.startPrice} ₽`);
  else bid.input.node.setAttribute('placeholder', `$${bid.startPrice}`);
}

function setPlaceHolders() {
  bids.forEach(setPlaceHolder);
}

function setPseudoTextRight() {
  showChar(activeBid.char.char);
  activeBid.char.node.style.left = `${activeBid.measurer.size + padding.left + (activeBid.char.node.clientWidth)}px`;
}

function setPseudoTextLeft() {
  showChar(activeBid.char.char);
  activeBid.char.node.style.left = `${padding.left}px`;
  addInputPadding();
}

function measurePseudoText() {
  activeBid.measurer.textContent = getActiveBidValue();
  activeBid.measurer.size = activeBid.measurer.clientWidth;
}

function setPseudoText() {
  measurePseudoText();
  if (activeBid.char.char === '₽') setPseudoTextRight();
  else setPseudoTextLeft();
}

function fixBidInput(event) {
  const { target } = event;
  // eslint-disable-next-line require-unicode-regexp
  setActiveBidValue(target.value.replace(/\D/g, ''));
}

function collectData() {
  const data = new FormData();
  activeBid.currentValue = getActiveBidValue();
  data.append('_token', token.getToken());
  data.append('price', getActiveBidValue());
  data.append('lot_id', activeBid.form.id);
  data.append('auction_member_id', activeBid.form.memberId);
  return data;
}

function sendBid(url) {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({
      url,
      method: 'POST',
      body: collectData(),
    }, (code, response) => {
      const data = JSON.parse(response);
      if (code === 200 && data.success) resolve(data);
      else reject(data);
    });
  });
}

function handleSubmit() {
  activeBid.lastSubmitted = getActiveBidValue();
  hideSubmit();
  setState();
  changeSubmitText();
  setManipulation();
  activeBid = null;
}

function handleUpdateSubmit() {
  hideSubmit();
  setState();
  if (activeBid.state === 0) {
    hideText();
    hideChar();
    showPlaceHolder();
    activeBid.input.node.removeAttribute('value');
    resetActiveForm();
    delete activeBid.lastSubmitted;
    delete activeBid.currentValue;
    changeSubmitText();
    setManipulation();
    activeBid = null;
  } else {
    activeBid.lastSubmitted = getActiveBidValue();
    setPseudoText();
    changeSubmitText();
    setManipulation();
  }
}

function addBid() {
  setPseudoText();
  disableSubmit();
  sendBid(activeBid.form.addUrl)
    .then(handleSubmit)
    // eslint-disable-next-line no-console
    .catch((data) => { console.error(data); });
}

function updateBid() {
  disableSubmit();
  sendBid(activeBid.form.addUrl)
    .then(handleUpdateSubmit)
    // eslint-disable-next-line no-console
    .catch((data) => { console.error(data); });
}

function discardBidState0() {
  activeBid.changed = false;
  resetActiveForm();
  showPlaceHolder();
  hideChar();
  hideSubmit();
  hideText();
  activeBid = null;
}

function discardBidState1() {
  activeBid.changed = false;
  setActiveBidValue(activeBid.currentValue);
  hideSubmit();
  setState();
  setPseudoText();
  activeBid = null;
}

function discardBidChanges() {
  if (activeBid.state === 0) discardBidState0();
  else discardBidState1();
}

function clearBidChanges() {
  if (getActiveBidValue() !== '') {
    activeBid.changed = false;
    setActiveBidValue('');
    if (activeBid.state === 1) enableSubmit();
    else disableSubmit();
  }
}

function onFocus(event) {
  const { target } = event;
  if (activeBid) {
    const [currentBid] = findBidByField(target, 'input');
    if (currentBid.form.node !== activeBid.form.node) {
      hideDiscardManupulation();
      discardBidChanges();
      [activeBid] = findBidByField(target, 'input');
      hidePlaceHolder();
      showText();
    }
  } else {
    [activeBid] = findBidByField(target, 'input');
    hidePlaceHolder();
    showText();
    showManipulation();
    changeToCancelManipulation();
  }
  hideChar();
  showSubmit();
}

function onInput(event) {
  fixBidInput(event);
  const value = getActiveBidValue();
  if (value.length > 0) {
    addChangedInputClass();
    activeBid.changed = true;
    showDiscardManipulation();
    if (shouldEnableSumbitInput(value)) enableSubmit();
    else disableSubmit();
  } else {
    removeChangedInputClass();
    if (activeBid.lastSubmitted && value !== activeBid.lastSubmitted) activeBid.changed = true;
    else activeBid.changed = false;
  }
  measurePseudoText();
}

function onBlur(event) {
  const { relatedTarget } = event;
  if (shouldDiscardChanged(relatedTarget)) {
    if (activeBid.changed && activeBid.input.node.value !== '') {
      hideSubmit();
      setPseudoText();
    } else {
      hideDiscardManupulation();
      hideChar();
      discardBidChanges();
    }
  }
}

function onSubmit(event) {
  event.preventDefault();
  if (activeBid.submit.isActive) {
    if (activeBid.state === 0) addBid();
    else updateBid();
  }
}

function checkActiveBid(target) {
  if (!(activeBid && findBidByField(target, 'input').length > 0)) {
    [activeBid] = findBidByField(target.parentElement, 'form');
  }
  if (!activeBid) [activeBid] = findBidByField(target, 'form');
}

function onFormClick(event) {
  const { target } = event;
  checkActiveBid(target);
  if (isCancelManipulation(target)) {
    event.preventDefault();
    setActiveBidFocus();
    clearBidChanges();
  } else if (isEditManipulation(target)) {
    event.preventDefault();
    setActiveBidFocus();
    hideSubmit();
    changeToCancelManipulation();
    showSubmit();
  }
}

function subscribeBid(bid) {
  bid.form.node.addEventListener('click', onFormClick);
  bid.input.node.addEventListener('input', onInput);
  bid.input.node.addEventListener('change', onInput);
  bid.input.node.addEventListener('focus', onFocus);
  bid.input.node.addEventListener('blur', onBlur);
  bid.form.node.addEventListener('submit', onSubmit);
}

function subscribeEvents() {
  bids.forEach(subscribeBid);
}

function subscribe() {
  subscribeEvents();
}

function initInitialized(bid) {
  activeBid = bid;
  activeBid.state = 1;
  activeBid.currentValue = getActiveBidValue();
  activeBid.lastSubmitted = getActiveBidValue();
  hidePlaceHolder();
  showText();
  setPseudoText();
  setEditBidSubmitText();
  showManipulation();
  addEditManipulationClass();
}

function findInitialized(acc, bid) {
  if (bid.initialized) acc.push(bid);
  return acc;
}

function setInitialized() {
  initializedBids = bids.reduce(findInitialized, []);
  initializedBids.forEach(initInitialized);
  activeBid = null;
}

export function init() {
  if (who()) {
    findElements();
    setPaddingInfo();
    setInitialized();
    setPlaceHolders();
    subscribe();
  }
}

import * as socket from 'auction/socket';
import request from 'auction/auctionRequest';
import trigger from 'utilities/trigger';
import { getLang } from 'utilities/lang';
import * as formatMoney from 'utilities/formatMoney';

let container;
let raiseButton;
let currentSum;
let onlineInfo;
let bidRates;
let onlineStream;
let ytFrame;

let lotInfo;
let lotAbout;
let correspondenseBid;

let currentLang;
let userId;
let changingObj = {};

function who() {
  return document.querySelector('.auction');
}

function findStatus(array, statusClass) {
  return array.reduce((acc, item) => {
    if (item.classList.contains(statusClass)) acc.push(item);
    return acc;
  }, [])[0];
}

function setBidRatesObj(array) {
  return {
    self: findStatus(array, 'selfRate'),
    hall: findStatus(array, 'hallRate'),
    another: findStatus(array, 'anotherRate'),
    start: findStatus(array, 'startRate'),
  };
}

function findLotElements() {
  lotInfo = document.querySelector('.currentLotInfo');
  lotAbout = document.querySelector('.aboutCurrentLot');
  correspondenseBid = document.querySelector('.correspondenseSum');
}

function findOnlineElements() {
  container = document.querySelector('.onlineAuction');
  onlineStream = container.querySelector('.onlineStream');
  ytFrame = container.querySelector('#online');
  raiseButton = container.querySelector('.bidRaise');
  currentSum = container.querySelector('.currentSum');
  onlineInfo = container.querySelector('.onlineInfo');
  bidRates = setBidRatesObj([].slice.call(container.querySelectorAll('.rateStatus')));
}

function setChangingObj() {
  changingObj = {
    lotName: lotInfo.querySelector('.currentLotName'),
    lotAuthor: lotInfo.querySelector('.currentLotAuthor'),
    lotYear: lotInfo.querySelector('.lotYear'),
    lotMaterial: lotInfo.querySelector('.lotMaterial'),
    lotSize: lotInfo.querySelector('.lotSize'),
    lotText: lotAbout.querySelector('.aboutCurrentLotText'),
    lotId: document.querySelector('.onlineChar'),
    rateStep: document.querySelector('.currentStep'),
    estimate: document.querySelector('.estimate'),
    startPrice: document.querySelector('.startPrice'),
    correspondenseBid,
    bidRaise: raiseButton,
    currentBid: raiseButton.querySelector('.newBid'),
  };
}

function setUserId() {
  userId = parseInt(onlineInfo.dataset.id, 10);
}

function setPausedState() {
  container.classList.add('onlineAuction-is-paused');
}

function removePausedState() {
  container.classList.remove('onlineAuction-is-paused');
}

function fixLink(link, lot) {
  const linkHref = link.dataset.url;
  const linkHrefArr = linkHref.split('/');
  linkHrefArr[linkHrefArr.length - 2] = lot.id;
  const newLink = linkHrefArr.join('/');
  link.setAttribute('data-url', newLink);
}

function setLang() {
  currentLang = getLang();
}

function showAuctionControls() {
  onlineInfo.classList.remove('onlineInfo-is-hidden');
}

function hideAuctionControls() {
  onlineInfo.classList.add('onlineInfo-is-hidden');
}

function changeStreamLink(data) {
  ytFrame.src = `${data.link}?autoplay=1`;
}

function updateCurrentSum(data) {
  currentSum.textContent = formatMoney.format(data[`current_bid_${currentLang}`]);
}

function updateCurrentStep(data) {
  changingObj.rateStep.textContent = formatMoney.format(data[`current_step_${currentLang}`]);
}

function updateCurrentBid(data) {
  changingObj.currentBid.textContent = formatMoney.format(data[`next_bid_${currentLang}`]);
}

function isBidLeading(id) {
  return id === userId;
}

function showLeadingBlock() {
  onlineStream.classList.add('js-bid-is-leading');
}

function hideLeadingBlock() {
  onlineStream.classList.remove('js-bid-is-leading');
}

function checkForLeadership(data) {
  if (isBidLeading(data.user_id)) showLeadingBlock();
  else hideLeadingBlock();
}

function showRateStatus(rate) {
  rate.classList.add('rateStatus-is-active');
}

function clearActiveBidStatus() {
  Object.keys(bidRates).forEach((key) => {
    bidRates[key].classList.remove('rateStatus-is-active');
  });
}

function setBidLabel(data) {
  clearActiveBidStatus();
  if (isBidLeading(data.user_id)) showRateStatus(bidRates.self);
  else if (data.user_id === null) showRateStatus(bidRates.hall);
  else showRateStatus(bidRates.another);
}

function resetBidLabel() {
  clearActiveBidStatus();
  showRateStatus(bidRates.start);
}

function onRaiseButtonClick(event) {
  event.preventDefault();
  request(raiseButton.dataset.url);
}

function onAuctionOpen() {
  showAuctionControls();
  removePausedState();
}

function onBidUpdate(event) {
  const { data } = event;
  updateCurrentSum(data);
  updateCurrentStep(data);
  updateCurrentBid(data);
  checkForLeadership(data);
  setBidLabel(data);
}

function onAuctionPause() {
  hideAuctionControls();
  setPausedState();
}

function onStreamUpdate(event) {
  const { data } = event;
  changeStreamLink(data);
}

function changeLotId(lot) {
  changingObj.lotId.textContent = lot.position;
}

function changeLotInfo(lot) {
  changingObj.lotAuthor.textContent = lot[`author_${currentLang}`];
  changingObj.lotName.textContent = lot[`title_${currentLang}`];
  changingObj.lotYear.textContent = lot[`year_${currentLang}`];
  changingObj.lotMaterial.textContent = lot[`material_${currentLang}`];
  changingObj.lotSize.textContent = lot[`size_${currentLang}`];
  changingObj.lotText.textContent = lot[`description_${currentLang}`];
}

function setLotCorrespondenseBid(lot) {
  if (lot[`correspondense_bid_${currentLang}`]) {
    changingObj.correspondenseBid.textContent = lot[`correspondense_bid_${currentLang}`];
    changingObj.correspondenseBid.parentElement.classList.remove('lotPurchaseItem-is-inactive');
  } else {
    changingObj.correspondenseBid.textContent = '';
    changingObj.correspondenseBid.parentElement.classList.add('lotPurchaseItem-is-inactive');
  }
}

function changeLotPurchaseInfo(lot) {
  changingObj.rateStep.textContent = formatMoney.format(lot[`step_${currentLang}`]);
  changingObj.startPrice.textContent = formatMoney.format(lot[`start_price_${currentLang}`]);
  changingObj.estimate.textContent = formatMoney.format(lot[`price_${currentLang}`]);

  setLotCorrespondenseBid(lot);
}

function fixInitialMoney(node) {
  const { textContent } = node;
  // eslint-disable-next-line no-param-reassign
  node.textContent = formatMoney.format(textContent);
}

function initFormatMoneyInfo() {
  fixInitialMoney(currentSum);
  fixInitialMoney(changingObj.currentBid);
  fixInitialMoney(changingObj.startPrice);
  if (correspondenseBid) fixInitialMoney(correspondenseBid);
}

function changeCurrentSum(lot) {
  currentSum.textContent = formatMoney.format(lot[`start_price_${currentLang}`]);
}

function changeLinks(lot) {
  fixLink(changingObj.bidRaise, lot);
}

function changeCurrentBid(lot) {
  changingObj.currentBid.textContent = formatMoney.format(lot[`start_price_${currentLang}`]);
}

function updateGallery(lot) {
  const { gallery } = lot;
  trigger(document, 'gallery:update', false, 'UIEvent', gallery);
}

function onLotUpdate(event) {
  const { data } = event;
  const { lot } = data;
  hideLeadingBlock();
  changeLotId(lot);
  changeLotInfo(lot);
  changeLotPurchaseInfo(lot);
  updateGallery(lot);
  changeCurrentBid(lot);
  changeCurrentSum(lot);
  resetBidLabel();
  changeLinks(lot);
}

function onAuctionClose(event) {
  location.href = event.data;
}

function subscribeSocketEvents() {
  document.addEventListener('auction:open', onAuctionOpen);
  document.addEventListener('auction:pause', onAuctionPause);
  document.addEventListener('auction:closed', onAuctionClose);
  document.addEventListener('currentSum:update', onBidUpdate);
  document.addEventListener('stream:update', onStreamUpdate);
  document.addEventListener('lots:update', onLotUpdate);
}

function subscribe() {
  raiseButton.addEventListener('click', onRaiseButtonClick);

  subscribeSocketEvents();
}

export function init() {
  if (who()) {
    socket.init();
    findOnlineElements();
    findLotElements();
    setChangingObj();
    setUserId();
    setLang();
    formatMoney.init(currentLang);
    initFormatMoneyInfo();
    subscribe();
  }
}

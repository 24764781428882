/* global Promise */

import * as regScroll from 'registrationScroll';
import * as nanoajax from 'nanoajax';
import * as token from 'utilities/token';
import * as phoneField from 'form/phone';
import * as validate from 'form/validate';
import * as passDateField from 'form/passportData';
import * as overlay from './overlay';

let form;
let phone;
let name;
let email;
let password;
let passData;
let passDate;
let passIssued;
let onlineRadio;
let personalRadio;
let agreeCheckbox;
let passDataCeckbox;
let dataProcessingCheckbox;

let isVip;

/* eslint-disable */

let isNameOk;
let isPhoneOk;
let isEmailOk;
let isPassdDataOk;
let isPassDateOk;
let isRulesAgreementChecked;
let isPersonalDataChecked;
let isPassPortDataChecked;
let isPassIssuedOk;
let isOnlineRadioChecked;
let isPersonalRadioChecked;
let isPasswordOk;

/* eslint-enable */

let submit;

function who() {
  return document.querySelector('.vipRegistration') || document.querySelector('.acquiring .registrationFields');
}

function findElements() {
  form = document.querySelector('.vipRegistration') || document.querySelector('.profileInfoForm');
  isVip = form.classList.contains('vipRegistration');
  name = form.querySelector('#fio');
  phone = form.querySelector('#phone');
  email = form.querySelector('#email');
  passData = form.querySelector('#passData');
  passDate = form.querySelector('#passDate');
  passIssued = form.querySelector('#passIssued');
  submit = form.querySelector('.registrationSubmit');
  onlineRadio = form.querySelector('#personally');
  personalRadio = form.querySelector('#online');
  agreeCheckbox = form.querySelector('#rules_agree');
  passDataCeckbox = form.querySelector('#passportData');
  dataProcessingCheckbox = form.querySelector('#data_processing');
  password = form.querySelector('#password');
}

function showPassportDataCheckbox() {
  passDataCeckbox.nextElementSibling.classList.remove('personalInfoAgree-is-hidden');
}

function hidePassportDataCheckbox() {
  passDataCeckbox.nextElementSibling.classList.add('personalInfoAgree-is-hidden');
}

function showField(field) {
  field.removeAttribute('disabled');
  field.parentElement.classList.remove('reigstrationFieldItem-is-inactive');
}

function hideField(field) {
  field.setAttribute('disabled', true);
  field.parentElement.classList.add('reigstrationFieldItem-is-inactive');
}

function showFields() {
  showField(passData);
  showField(passDate);
  showField(passIssued);
}

function hideFields() {
  hideField(passData);
  hideField(passDate);
  hideField(passIssued);
}

function enableSubmit() {
  submit.removeAttribute('disabled');
}

function disableSubmit() {
  submit.setAttribute('disabled', true);
}

function initPhone() {
  phoneField.init(phone, form);
}

function initPassDate() {
  passDateField.init(passDate);
}

function isStandartValid() {
  return isNameOk && isEmailOk && isRulesAgreementChecked && isPersonalDataChecked;
}

function isPassDataValid() {
  return isPassdDataOk && isPassDateOk && isPassIssuedOk;
}

function isSpesificValid() {
  if (!isPassPortDataChecked) return isPassDataValid();
  return true;
}

function initFields() {
  initPhone();
  initPassDate();
}

function hidePassDataFields() {
  if (isPassPortDataChecked) hideFields();
  else showFields();
}

function setRegistrationButtonState() {
  if (isVip) {
    if (isPasswordOk && isStandartValid() && isSpesificValid()) enableSubmit();
    else disableSubmit();
  } else {
    // eslint-disable-next-line no-lonely-if
    if (isNameOk && isEmailOk && isSpesificValid()) enableSubmit();
    else disableSubmit();
  }
}

function onInput(event) {
  const { target } = event;

  if (target === onlineRadio) {
    isOnlineRadioChecked = target.checked;
    hidePassportDataCheckbox();
  }
  if (target === personalRadio) {
    isPersonalRadioChecked = target.checked;
    showPassportDataCheckbox();
  }
  if (target === passDataCeckbox) {
    isPassPortDataChecked = target.checked;
    hidePassDataFields();
  }

  if (target === name) isNameOk = validate.validateName(target);
  if (target === email) isEmailOk = validate.validateEmail(target);
  if (target === phone) isPhoneOk = validate.validatePhone(target);
  if (target === passData) isPassdDataOk = validate.validateSimpleField(target);
  if (target === passDate) isPassDateOk = validate.validatePassDate(target);
  if (target === passIssued) isPassIssuedOk = validate.validateSimpleField(target);
  if (target === password) isPasswordOk = validate.validatePassword(target);
  if (target === agreeCheckbox) isRulesAgreementChecked = target.checked;
  if (target === dataProcessingCheckbox) isPersonalDataChecked = target.checked;

  setRegistrationButtonState();
}

function collectData() {
  const data = new FormData(form);

  data.append('_token', token.getToken());
  data.append('phone_number', phoneField.makeUpPhone());

  return data;
}

function sendForm() {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({
      url: form.action,
      method: 'post',
      body: collectData(),
    }, (code, response) => {
      const data = JSON.parse(response);
      if (code === 200 && data.success) resolve(data);
      else reject(data);
    });
  });
}

function showMessage(data) {
  overlay.show('errorPopup', data.message);
}

function handleRedirect(data) {
  if (data.redirect_to) location.href = data.redirect_to;
}

function handleError(data) {
  if (data.message) showMessage(data);
}

function submitForm() {
  sendForm()
    .then(handleRedirect)
    .catch(handleError);
}

function onSubmit(event) {
  event.preventDefault();
  submitForm();
}

function isOnlineRadio(target) {
  return target.htmlFor === onlineRadio.id;
}

function onClick(event) {
  const { target } = event;
  if (isOnlineRadio(target)) {
    if (isPassPortDataChecked) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }
}

function setPhoneValidation() {
  isPhoneOk = validate.validatePhone(phone);
}

function subscribe() {
  form.addEventListener('input', onInput);
  form.addEventListener('change', onInput);
  form.addEventListener('submit', onSubmit);
  form.addEventListener('click', onClick);
  document.addEventListener('phone:ready', setPhoneValidation);
}

function setValiadationVariables() {
  isNameOk = validate.validateName(name);
  isEmailOk = validate.validateEmail(email);
  isPassdDataOk = validate.validateSimpleField(passData);
  isPassDateOk = validate.validatePassDate(passDate);
  isPassIssuedOk = validate.validateSimpleField(passIssued);
  if (isVip) {
    isRulesAgreementChecked = agreeCheckbox.checked;
    isPersonalDataChecked = dataProcessingCheckbox.checked;
    isPasswordOk = validate.validatePassword(password);
  }
}

export function init() {
  if (who()) {
    findElements();
    if (isVip) {
      regScroll.init(form);
    }
    initFields();
    subscribe();
    setValiadationVariables();
  }
}
